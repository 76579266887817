import { Component, OnInit } from '@angular/core';
import { Run } from '../shared/runs/run';
import { Player } from '../shared/player/player';
import { CrudRunsService } from '../shared/runs/crud-runs.service';
import { CrudPlayerService } from '../shared/player/crud-player.service';
import { LoadingService } from '../shared/loading/loading.service';
import { Reference } from '@angular/fire/compat/storage/interfaces';
import { Court } from '../shared/court/court';
import { CrudCourtService } from '../shared/court/crud-court.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-runs-table',
  templateUrl: './runs-table.component.html',
  styleUrls: ['./runs-table.component.css']
})
export class RunsTableComponent implements OnInit {
  runs: Run[];
  filteredRuns: Run[]
  players: Player[];
  courts: Court[];

  newRun: Run;
  selectedRun: Run;

  page: string = 'table';
  types: string[] = ['1 on 1', 'Full Court', 'Half Court'];

  searchTerm: BehaviorSubject<string>;
  filter: BehaviorSubject<string>;
  
  constructor(
    private crudService: CrudRunsService,
    private playerCrud: CrudPlayerService,
    private courtCrud: CrudCourtService,
    public loader: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
      activatedRoute.queryParams.subscribe(params => {
        this.page = params['page'];
        console.log(params);
        if (!this.page) {
          this.page = 'table';
        }

        if (this.page == 'detail' && !this.selectedRun) {
          this.switchView('table');
        }
      })

      this.newRun = {
        imageUrl: '',
        title: '',
        description: '',
        hostFullName: '',
        email: '',
        cellNumber: '',
        courtID: '',
        type: '',
        time: '',
        date: '',
        playerIds: []
      }

      this.selectedRun = {
        imageUrl: '',
        title: '',
        description: '',
        hostFullName: '',
        email: '',
        cellNumber: '',
        courtID: '',
        type: '',
        time: '',
        date: '',
        playerIds: []
      }

      this.searchTerm = new BehaviorSubject('');
      this.filter = new BehaviorSubject('');
  }

  ngOnInit() {
    this.loader.showLoading();
    this.crudService.getRunsList().subscribe((data) => {
      this.runs = data;
      this.filteredRuns = this.runs;
      if (this.runs.length == 0) {
        this.page = 'empty';
      }
      this.loader.hideLoading();
      console.log(this.runs);
    })

    this.loader.showLoading();
    this.playerCrud.getPlayersList().subscribe((data: Player[]) => {
      this.players = data;
      if (this.players.length == 0) {
        this.page = 'empty';
      }
      this.loader.hideLoading();
    });

    this.loader.showLoading();
    this.courtCrud.getCourtsList().subscribe((data: Court[]) => {
      this.courts = data;
      this.loader.hideLoading();
    });

    this.searchTerm.subscribe((searchTerm) => {
      this.filterList(searchTerm, this.filter.getValue());
    })

    this.filter.subscribe((filter) => {
      this.filterList(this.searchTerm.getValue(), filter);
    })
  }

  switchView(view: string) {
    this.filteredRuns = this.runs;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: view
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });
  }

  onRunClick(run: Run) {
    this.selectedRun = run;
    this.switchView('detail');
  }

  updateRun() {
    this.loader.showLoading();
    this.crudService.updateRun(this.selectedRun.id, this.selectedRun).then(() => {
      this.switchView('table');
      this.loader.hideLoading();
      alert('Run updated successfully');
    }, () => {
      alert('Error');
    })
  }

  onSubmit() {
    this.loader.showLoading();
    this.crudService.addRun(this.newRun).then(() => {
      this.switchView('table');
      this.loader.hideLoading();
      alert("Run added");
    })
  }

  getPlayer(id: string): Player {
    return this.players.find((player) => player.id == id);
  }

  getCourt(id: string): Court {
    return this.courts.find((court) => court.id == id);
  }

  getURL(ref: Reference, parameter: string){
    ref.getDownloadURL().then((url) => {
      console.log(url);
      switch (parameter) {
        case 'selected':
          this.selectedRun.imageUrl = url;
          break;
        case 'new':
          this.newRun.imageUrl = url;
          break;
      }
    })
  }

  filterList(_searchTerm: string, _filter: string) {
    this.filteredRuns = this.runs.filter((run) => {
      return run.title.toLowerCase().includes(_searchTerm.toLowerCase());
    }).filter((run) => {
      return run.type.toLowerCase().includes(_filter.toLowerCase());
    })
  }
}
