import { Component } from '@angular/core';
import { User } from '../shared/user/user';
import { CrudPlayerService } from '../shared/player/crud-player.service';
import { CrudUserService } from '../shared/user/crud-user.service';
import { LoadingService } from '../shared/loading/loading.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.css']
})
export class UsersTableComponent {
  users: User[];
  filteredUsers: User[];

  selectedUser: User;
  newUser: User;

  frequencyOfPlayOptions = ['Rarely', 'Weekly', 'Monthly'];
  userTypes = ['Fan', 'Coach', 'Casual Player', 'Pro Player'];

  page: string = 'table';

  constructor(
    private crudService: CrudUserService,
    public loader: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  )
  {
    activatedRoute.queryParams.subscribe(params => {
      this.page = params['page'];
      console.log(params);
      if (!this.page) {
        this.page = 'table';
      }

      if (this.page == 'detail' && !this.selectedUser) {
        this.switchView('table');
      }
    })

    this.selectedUser = {
      address: '',
      cellPhoneNumber: '',
      dateOfBirth: '',
      email: '',
      frequencyOfPlay: '',
      fullName: '',
      typeOfUser: ''
    }

    this.newUser = {
      address: '',
      cellPhoneNumber: '',
      dateOfBirth: '',
      email: '',
      frequencyOfPlay: '',
      fullName: '',
      typeOfUser: ''
    }
  }

  ngOnInit() {
    this.loader.showLoading();
    this.crudService.getUsersList().subscribe((data: User[]) => {
      this.users = data;
      this.filteredUsers = this.users;
      if (this.users.length == 0) {
        this.page = 'empty';
      }
      this.loader.hideLoading();
    })
  }

  onUserClick(user: User) {
    console.log('Clicked user: ' + user.fullName);
    this.selectedUser = user;
    this.switchView('detail');
  }

  switchView(view: string) {
    this.filteredUsers = this.users;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: view
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });
  }

  updateUser() {
    this.crudService.updateUser(this.selectedUser.id, this.selectedUser).then(() => {
      alert('Team updated successfully')
      this.switchView('table');
    }, (error) => {
      // Handle the error scenario here
      console.log(error);
    });
  }

  confirmDelete() {
    const confirmation = window.confirm('Are you sure you want to delete this user?');
    if (confirmation) {
      this.crudService.deleteUser(this.selectedUser.id).then(() => {
        alert('User deleted successfully');
        this.switchView('table');
      });
      
    } else {
      alert("Error deleting team");
    }
  }

  sendResetEmail() {
    this.crudService.sendpasswordEmail(this.selectedUser.email);
    alert("Password reset email sent to " + this.selectedUser.email);
  }

  filterList(searchTerm: string) {
    this.filteredUsers = this.users.filter((user) => {
      return user.fullName.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }
}
