export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyDGlrA0koLNhX4hs3u4zrW6Aw6YRNhD_gk",
    authDomain: "the-zone-prod.firebaseapp.com",
    projectId: "the-zone-prod",
    storageBucket: "the-zone-prod.appspot.com",
    messagingSenderId: "104271862269",
    appId: "1:104271862269:web:d6358f191ff4c7999ee194",
    measurementId: "G-FN2PVPHGBX"
  }
};
