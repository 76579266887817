import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Reference } from '@angular/fire/compat/storage/interfaces';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css']
})
export class ImageUploadComponent implements OnChanges {
  @Input() src = '';
  @Output() refEvent = new EventEmitter<Reference>();

  constructor(private storage: AngularFireStorage) {}
  

  uploadFile(ev: Event) {
    alert("Uploading image");
    const file: File = (ev.target as HTMLInputElement).files[0];
    const filePath = Date.now() + file.name;
    this.storage.upload(filePath, file).then((snapshot) => {
      this.refEvent.emit(snapshot.ref);
    }, (error) => console.log(error));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.src = changes.src.currentValue;
  }
}
