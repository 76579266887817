<div [ngSwitch]="page">
    <div *ngSwitchCase="'detail'" class="p-4">
        <button type="button" class="btn btn-tertiary" mdbRipple (click)="switchView('table')">
            <i class='bx bx-arrow-back'></i>
            <span>Back to teams</span>
        </button>

        <h3>Update Team</h3>
        <form (ngSubmit)="updateTeam(selectedTeam)">
            <div class="form-group">
                <label>Team Image</label>
                <app-image-upload [src]="selectedTeam.teamImage" (refEvent)="getURL($event, 'selected')"></app-image-upload>

                <label class="mt-3">Team Name</label>
                <input type="text" placeholder="Team Name" [(ngModel)]="selectedTeam.teamName" name="teamName" class="form-input">

                <label>Team Description</label>
                <textarea [(ngModel)]="selectedTeam.teamDescription" name="teamDescription" class="form-input"></textarea>

                <label for="coach" class="d-block">Team Coach</label>
                <ng-select
                    name="teamCoachId"
                    placeholder="Select coach"
                    [(ngModel)]="selectedTeam.teamCoachId"
                    class="w-50">
                    <ng-option *ngFor="let coach of coaches" [value]="coach.id">{{ coach.firstName }} {{ coach.lastName }}</ng-option>
                </ng-select>

                <label for="captain" class="d-block">Team Captain</label>
                <ng-select
                    name="teamCaptainId"
                    placeholder="Select captain"
                    [(ngModel)]="selectedTeam.teamCaptainId"
                    class="w-50">
                    <ng-option *ngFor="let player of players" [value]="player.id">{{ player.firstName }} {{ player.lastName }}</ng-option>
                </ng-select>

                <label for="players" class="d-block">Team Players</label>
                <ng-select
                    name="teamPlayers"
                    [multiple]="true"
                    [(ngModel)]="selectedTeam.teamPlayers"
                    placeholder="Select Players"
                    class="w-75">
                    <ng-option *ngFor="let player of players" [value]="player.id">{{ player.firstName }} {{ player.lastName }}</ng-option>
                </ng-select>
            </div>
            
            <button type="button" class="btn btn-danger" (click)="confirmDelete(selectedTeam)">Delete</button>

            <button type="submit" class="btn btn-info ms-2">Update</button>
        </form>

        <h3 class="mt-4">Team Statistics</h3>
        <form (ngSubmit)="updateTeamStatistics()">
            <div class="form-group container-fluid">
                <div class="row">
                    <div class="col-xxl-2 col-6">
                        <label>Total Brands</label>
                        <input type="number" [(ngModel)]="selectedTeamStatistics.totalBrands" name="totalBrands"
                            class="number-input" min="0">
                    </div>
                    
                    <div class="col-xxl-2 col-6">
                        <label>Total Coaches</label>
                        <input type="number" [(ngModel)]="selectedTeamStatistics.totalCoaches" name="totalCoaches"
                            class="number-input" min="0">
                    </div>
                    
                    <div class="col-xxl-2 col-6">
                        <label>Total Competitions</label>
                        <input type="number" [(ngModel)]="selectedTeamStatistics.totalCompetitions" name="totalCompetitions"
                            class="number-input" min="0">
                    </div>

                    <div class="col-xxl-2 col-6">
                        <label>Total Draws</label>
                        <input type="number" [(ngModel)]="selectedTeamStatistics.totalDraws" name="totalDraws"
                            class="number-input" min="0">
                    </div>
                </div>

                <div class="row">
                    <div class="col-xxl-2 col-6">
                        <label>Total Fans</label>
                        <input type="number" [(ngModel)]="selectedTeamStatistics.totalFans" name="totalFans"
                            class="number-input" min="0">
                    </div>
                    
                    <div class="col-xxl-2 col-6">
                        <label>Total Losses</label>
                        <input type="number" [(ngModel)]="selectedTeamStatistics.totalLosses" name="totalLosses"
                            class="number-input" min="0">
                    </div>
                    
                    <div class="col-xxl-2 col-6">
                        <label>Total Penalties</label>
                        <input type="number" [(ngModel)]="selectedTeamStatistics.totalPenalties" name="totalPenalties"
                            class="number-input" min="0">
                    </div>

                    <div class="col-xxl-2 col-6">
                        <label>Total Players</label>
                        <input type="number" [(ngModel)]="selectedTeamStatistics.totalPlayers" name="totalPlayers"
                            class="number-input" min="0">
                    </div>
                </div>

                <div class="row">
                    <div class="col-xxl-2 col-6">
                        <label>Total Trophies</label>
                        <input type="number" [(ngModel)]="selectedTeamStatistics.totalTrophies" name="totalTrophies"
                            class="number-input" min="0">
                    </div>
                    
                    <div class="col-xxl-2 col-6">
                        <label>Total Wins</label>
                        <input type="number" [(ngModel)]="selectedTeamStatistics.totalWins" name="totalWins"
                            class="number-input" min="0">
                    </div>

                    <div class="col-xxl-2 col-6">
                        <label>Total Points</label>
                        <input type="number" [(ngModel)]="selectedTeamStatistics.totalPoints" name="totalPoints"
                            class="number-input" min="0">
                    </div>
                </div>
            </div>

            <button type="submit" class="btn btn-info">Update</button>
        </form>
    </div>

    <div *ngSwitchCase="'table'" class="p-4">
        <div *ngIf="!(loader.isLoading$ | async)">
            <app-search-bar label="Search Teams" (onChange)="filterList($event)"></app-search-bar>
            <table class="table table-striped table-hover">
                <thead>
                    <th scope="col">Team Name</th>
                    <th scope="col">Team Coach</th>
                    <th scope="col">Team Captain</th>
                </thead>
    
                <tbody class="table-group-divider table-divider-color divider-color">
                    <tr *ngFor="let team of filteredTeams " (click)="onteamClick(team, team.id)" scope="row">
                        <td>
                            <img [src]="team.teamImage">
                            {{ team.teamName }}
                        </td>
                        <td>{{ getCoach(team.teamCoachId).firstName }} {{ getCoach(team.teamCoachId).lastName }}</td>
                        <td>{{ getPlayer(team.teamCaptainId).firstName }} {{ getPlayer(team.teamCaptainId).lastName }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="mx-auto w-25 mt-5" *ngIf="loader.isLoading$ | async">
            <div class="spinner-border text-warning" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>

    <div class="floating-button-container">
        <button class="float" (click)="switchView(page == 'form' ? 'table' : 'form')">
        <span class="add-icon">+</span>
        </button>
    </div>

    <div *ngSwitchCase="'empty'" class="p-4">
        <app-empty></app-empty>
    </div>

    <div *ngSwitchCase="'form'" class="p-4">
        <div class="text-center" *ngIf="loader.isLoading$ | async">
            <div class="spinner-border text-warning" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        
        <form (ngSubmit)="onSubmit()">
            <label>Team Image</label>
            <app-image-upload [src]="newTeam.teamImage" (refEvent)="getURL($event, 'new')"></app-image-upload>

            <label class="mt-3">Team Name</label>
            <input type="text" placeholder="Team Name" [(ngModel)]="teamName" name="teamName" class="form-input">
            
            <label>Team Description</label>
            <textarea [(ngModel)]="teamDescription" name="teamDescription" class="form-input"></textarea>

            <label for="coach" class="d-block">Team Coach</label>
            <ng-select
                name="teamCoachId"
                placeholder="Select coach"
                [(ngModel)]="teamCoachId"
                class="w-50">
                <ng-option *ngFor="let coach of coaches" [value]="coach.id">{{ coach.firstName }} {{ coach.lastName }}</ng-option>
            </ng-select>

            <label for="captain" class="d-block">Team Captain</label>
            <ng-select
                name="teamCaptainId"
                placeholder="Select captain"
                [(ngModel)]="teamCaptainId"
                class="w-50">
                <ng-option *ngFor="let player of players" [value]="player.id">{{ player.firstName }} {{ player.lastName }}</ng-option>
            </ng-select>

            <label for="players" class="d-block">Players</label>
            <ng-select
                name="players"
                [multiple]="true"
                [(ngModel)]="teamPlayers"
                placeholder="Select Players">
                <ng-option *ngFor="let player of players" [value]="player.id">{{ player.firstName }} {{ player.lastName }}</ng-option>
            </ng-select>

            <button type="submit" class="btn btn-info mt-2">Submit</button>
        </form>
    </div>
</div>