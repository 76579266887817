import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/compat/firestore';
import { Run } from './run';
import { Observable } from 'rxjs';
import { PushNotificationService } from '../services/push-notification.service';

@Injectable({
  providedIn: 'root'
})
export class CrudRunsService {
  private runsCollection: AngularFirestoreCollection<Run>;
  runs: Observable<Run[]>;

  constructor(private afs: AngularFirestore, private push: PushNotificationService) {
    this.runsCollection = afs.collection('Runs');
    this.runs = this.runsCollection.valueChanges({idField: 'id'});
  }

  addRun(run: Run): Promise<DocumentReference<Run>> {
    this.push.sendNotification('New run', 'A new run is roaming in your area, want to join?', 'Runs');
    return this.runsCollection.add(run);
  }

  getRunsList(): Observable<Run[]> {
    return this.runs;
  }

  updateRun(id: string, run: Run): Promise<void> {
    return this.runsCollection.doc(id).update(run);
  }

  deleteRun(id: string): Promise<void> {
    return this.runsCollection.doc(id).delete();
  }
}
