import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/compat/firestore';
import { Competition } from './competition';
import { Observable } from 'rxjs';
import { PushNotificationService } from '../services/push-notification.service';

@Injectable({
  providedIn: 'root'
})
export class CrudCompetitionService {
  private competitionsCollection: AngularFirestoreCollection<Competition>;
  competitions: Observable<Competition[]>;

  constructor(private afs: AngularFirestore, private push: PushNotificationService) {
    this.competitionsCollection = afs.collection('Competitions');
    this.competitions = this.competitionsCollection.valueChanges({idField: 'id'});
  }

  addCompetition(competition: Competition): Promise<DocumentReference> {
    this.push.sendNotification('New competition', 'A new competition has hit the streets, check it out!', 'Competitions')
      .then((ref) => console.log("Notificatin sent"));
    return this.competitionsCollection.add(competition);
  }

  getCompetitionsList(): Observable<Competition[]> {
    return this.competitions;
  }

  updateCompetition(id: string, competition: Competition) {
    this.competitionsCollection.doc(id).update(competition);
  }

  deleteCompetition(id: string): Promise<void> {
    return this.competitionsCollection.doc(id).delete();
  }
}
