import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { User } from './user';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class CrudUserService {
  private usersCollection: AngularFirestoreCollection<User>;
  users: Observable<User[]>;

  constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth) {
    this.usersCollection = afs.collection('Users');
    this.users = this.usersCollection.valueChanges({idField: 'id'});
  }

  getUsersList(): Observable<User[]> {
    return this.users;
  }

  updateUser(id: string, user: User): Promise<void> {
    return this.usersCollection.doc(id).update(user);
  }

  deleteUser(id: string) {
    return this.usersCollection.doc(id).delete();
  }

  sendpasswordEmail(email: string) {
    this.afAuth.sendPasswordResetEmail(email);
  }
}
