import { Component, OnInit } from '@angular/core';
import { CrudFansService } from '../shared/fans/crud-fan.service';
import { Observable } from 'rxjs';
import { Fan } from '../shared/fans/fan';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-fans-table',
  templateUrl: './fans-table.component.html',
  styleUrls: ['./fans-table.component.css']
})
export class FansTableComponent implements OnInit {
  fans: Fan[];
  isFormVisible: boolean;
  newFan: Fan;
  selectedFans: Fan; // Store the selected fan
  isDetailVisible = false;
  selectedFanId: string | null = null;
  isModalVisible: boolean = false;

  //showForm: boolean = false;
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  mobileNumber: string = '';
  teamName: string = '';

  page: string = 'table';

  constructor(private crudService: CrudFansService, private toastr: ToastrService) {}

  ngOnInit() {
    this.crudService.getFansList().subscribe((data: Fan[]) => {
      this.fans = data;
      console.log(this.fans); // Logging to check if the data is received
    });
  }

  onFansClick(fan: Fan,fanId: string ) {
    console.log('Clicked Fan'+fan.email);
    this.selectedFans = fan;
    this.selectedFanId = fanId;
    this.switchView('detail');
  }

  switchView(view: string) {
    this.page = view;
  }
  
  updateFans(player: Fan) {
    this.crudService.updateFan(this.selectedFanId, this.selectedFans).then(() => {
      alert('Fanxs updated successfully')
      this.isDetailVisible=false;
    }, (error) => {
      // Handle the error scenario here
    });
  }

  onSubmit() {
    const player = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      mobileNumber: this.mobileNumber,
      teamName: this.teamName,
    };
    this.crudService.addFan(player).then(() => {
      alert('Fan added successfully');
      this.isFormVisible = false;
    });
  }

  confirmDelete(selectedFans: any) {
    const confirmation = window.confirm('Are you sure you want to delete this user?');
    if (confirmation) {
      // Delete the selected fan
      this.crudService.deleteFan(this.selectedFans.id).then(()=>{
        alert("Fan deleted successfully");
        this.isDetailVisible = false;
      }).catch((error)=>{
        alert("Error deleting user")
      });
    }
  }


  // Component logic
  showForm(): void {
  this.isFormVisible = !this.isFormVisible; // Toggle the form visibility
  }


  hideForm() {
    this.isFormVisible = false;
  }
}
