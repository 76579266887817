<div [ngSwitch]="page">
    <div *ngSwitchCase="'detail'" class="form-container">
      <button type="button" class="btn btn-tertiary" mdbRipple (click)="switchView('table')">
        <i class='bx bx-arrow-back'></i>
        <span>Back to fans</span>
      </button>
    <h3>Update Fans</h3>
    <form (ngSubmit)="updateFans(selectedFans)">
        <div class="form-group">
            <label>Personal Information</label>
            <div class="form-inline">
                <input type="text" placeholder="First Name" [(ngModel)]="selectedFans.firstName" name="firstName" class="form-input">
                <input type="text" placeholder="Last Name" [(ngModel)]="selectedFans.lastName" name="lastName" class="form-input">
            </div>
        </div>

        <div class="form-group">
            <label>Contact Information</label>
            <div class="form-inline">
                <input type="email" placeholder="Email" [(ngModel)]="selectedFans.email" name="email" class="form-input">
                <input type="text" placeholder="Mobile Number" [(ngModel)]="selectedFans.mobileNumber" name="mobileNumber" class="form-input">
            </div>
        </div>

        <div class="form-group">
            <label>Team Information</label>
            <div class="form-inline">
                <input type="text" placeholder="Team Name" [(ngModel)]="selectedFans.teamName" name="teamName" class="form-input">
            </div>
        </div>

        <button type="button" class="delete-button" (click)="confirmDelete(selectedFans)">Delete</button>

        <button type="submit" class="update-button">Update</button>
    </form>
</div>

    <div *ngSwitchCase="'table'">
    <div>
      <div>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Name & Surname</th>
              <th>E-mail</th>
              <th>Mobile Number</th>
              <th>Team Name</th>
            </tr>
          </thead>
          <tbody class="table-group-divider table-divider-color divider-color">
            <tr *ngFor="let fans of fans " (click)="onFansClick(fans,fans.id)">
              <td>
                <img src="https://secure.gravatar.com/avatar/d09eaad01aea86c51b4f892b4f8abf6f?s=100&d=wavatar&r=g">
                {{ fans.firstName }} {{ fans.lastName }}
              </td>
              <td>{{ fans.email }}</td>
              <td>{{ fans.mobileNumber }}</td>
              <td>{{ fans.teamName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</div>


<div class="floating-button-container">
    <button class="float" (click)="switchView(page == 'form' ? 'table' : 'form')">
      <span class="add-icon">+</span>
    </button>
    <span class="add-Fans-text">Add Fans</span>
  </div>
  

  <div *ngSwitchCase="'form'" class="form-container">
    <form (ngSubmit)="onSubmit()" class="Fans-form">
        <!-- Group 1 -->
        <div class="form-group">
            <label>Personal Information</label>
            <div class="form-inline">
                <input type="text" placeholder="First Name" [(ngModel)]="firstName" name="firstName" class="form-input">
                <input type="text" placeholder="Last Name" [(ngModel)]="lastName" name="lastName" class="form-input">
            </div>
        </div>

        <!-- Group 2 -->
        <div class="form-group">
            <label>Contact Information</label>
            <div class="form-inline">
                <input type="email" placeholder="Email" [(ngModel)]="email" name="email" class="form-input">
                <input type="text" placeholder="Mobile Number" [(ngModel)]="mobileNumber" name="mobileNumber" class="form-input">
            </div>
        </div>

        <!-- Group 3 -->
        <div class="form-group">
            <label>Team Information</label>
            <div class="form-inline">
                <input type="text" placeholder="Team Name" [(ngModel)]="teamName" name="teamName" class="form-input">
            </div>
        </div>
        <button type="submit" class="update-button">Submit</button>
    </form>
</div>
  </div>
  