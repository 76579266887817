<div [ngSwitch]="page">
    <div *ngSwitchCase="'detail'" class="p-4">
        <button type="button" class="btn btn-tertiary" mdbRipple (click)="switchView('table')">
            <i class='bx bx-arrow-back'></i>
            <span>Back to games</span>
        </button>

        <h3>Update Game</h3>
        <form (ngSubmit)="updateGame(selectedGame)">
            <div class="form-group">
                <app-image-upload [src]="selectedGame.image" (refEvent)="getURL($event, 'selected')"></app-image-upload>
                
                <label class="d-block">Start Date</label>
                <input type="date" (change)="toTimeStamp($event, 'startDate')" [valueAsDate]="selectedGame.startTime.toDate()" name="startTime" class="form-input">

                <label class="d-block">Team 1</label>
                <ng-select
                    name="team1Id"
                    placeholder="Select Team 1"
                    [(ngModel)]="selectedGame.team1Id"
                    class="w-50">
                    <ng-option *ngFor="let team of teams" [value]="team.id">{{ team.teamName }}</ng-option>
                </ng-select>

                <label class="d-block">Team 2</label>
                <ng-select
                    name="team2Id"
                    placeholder="Select Team 2"
                    [(ngModel)]="selectedGame.team2Id"
                    class="w-50">
                    <ng-option *ngFor="let team of teams" [value]="team.id">{{ team.teamName }}</ng-option>
                </ng-select>
            </div>
            

            <button type="button" class="btn btn-danger" (click)="confirmDelete(selectedGame)">Delete</button>

            <button type="submit" class="btn btn-info ms-2">Update</button>
        </form>

        <h3 class="mt-3">Update Result</h3>
        <form (ngSubmit)="updateResult(selectedresult)">
            <div class="form-group">  
                <label class="d-block">Result Date</label>
                <input type="date" (change)="toTimeStamp($event, 'resultDate')" [valueAsDate]="selectedresult.resultDate.toDate()" name="startTime" class="form-input">

                <label class="d-block">Team 1 Score</label>
                <input type="number" [(ngModel)]="selectedresult.scoreTeam1" name="scoreTeam1" class="number-input">

                <label class="d-block">Team 2 Score</label>
                <input type="number" [(ngModel)]="selectedresult.scoreTeam2" name="scoreTeam2" class="number-input">

                <button type="submit" class="btn btn-info d-block mt-2">Update</button>
            </div>
            
        </form>
    </div>

    <div *ngSwitchCase="'table'" class="p-4">
        <table class="table table-striped table-hover" *ngIf="!(loader.isLoading$ | async)">
            <thead>
                <th scope="col">Date</th>
                <th>Start Time</th>
                <th scope="col">Team 1</th>
                <th scope="col">Team 2</th>
                <th>Status</th>
            </thead>

            <tbody class="table-group-divider table-divider-color divider-color">
                <tr *ngFor="let game of games " (click)="onGameClick(game, game.id)" scope="row">
                    <td>
                        <img [src]="game.image">
                        {{ game.date.toDate().toDateString() }}
                    </td>
                    <td>{{ game.startTime.toDate().toTimeString() }}</td>
                    <td>{{ getTeam(game.team1Id).teamName }}</td>
                    <td>{{ getTeam(game.team2Id).teamName }}</td>
                    <td>
                        <span class="status upcoming" *ngIf="upcoming(game)">Upcoming</span>
                        <span class="status completed" *ngIf="!upcoming(game)">Completed</span>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="mx-auto w-25 mt-5" *ngIf="loader.isLoading$ | async">
            <div class="spinner-border text-warning" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="'empty'" class="p-4">
        <app-empty></app-empty>
    </div>
    
    <div class="floating-button-container">
        <button class="float" (click)="switchView(page == 'form' ? 'table' : 'form')">
        <span class="add-icon">+</span>
        </button>
    </div>

    <div *ngSwitchCase="'form'" class="p-4">
        <div class="text-center" *ngIf="loader.isLoading$ | async">
            <div class="spinner-border text-warning" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        
        <form (ngSubmit)="onSubmit()">
            <app-image-upload [src]="newGame.image" (refEvent)="getURL($event, 'new')"></app-image-upload>
            
            <label class="d-block">Start Date</label>
            <input type="date" (change)="toTimeStamp($event, 'startDate')" name="startTime" class="form-input">

            <label class="d-block">Team 1</label>
            <ng-select
                name="team1Id"
                placeholder="Select Team 1"
                [(ngModel)]="newGame.team1Id"
                class="w-50">
                <ng-option *ngFor="let team of teams" [value]="team.id">{{ team.teamName }}</ng-option>
            </ng-select>

            <label class="d-block">Team 2</label>
            <ng-select
                name="team2Id"
                placeholder="Select Team 2"
                [(ngModel)]="newGame.team2Id"
                class="w-50">
                <ng-option *ngFor="let team of teams" [value]="team.id">{{ team.teamName }}</ng-option>
            </ng-select>

            <button type="submit" class="btn btn-info mt-2">Submit</button>
        </form>
    </div>
</div>