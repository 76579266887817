import { Component, OnInit } from '@angular/core';
import { CrudPlayerService } from '../shared/player/crud-player.service';
import { Observable } from 'rxjs';
import { Player } from '../shared/player/player';
import { ToastrService } from 'ngx-toastr';
import { PlayerStatistics } from '../shared/player/playerStatistics';
import { Reference } from '@angular/fire/compat/storage/interfaces';
import { LoadingService } from '../shared/loading/loading.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-players-table',
  templateUrl: './players-table.component.html',
  styleUrls: ['./players-table.component.css']
})
export class PlayersTableComponent implements OnInit {
  players: Player[];
  filteredPlayers: Player[];
  playerStatistics: PlayerStatistics[];
  isFormVisible: boolean;
  newPlayer: Player;
  selectedPlayer: Player; // Store the selected player
  selectedPlayerStatistics: PlayerStatistics;
  isDetailVisible = false;
  selectedPlayerId: string | null = null;
  isModalVisible: boolean = false;

  //showForm: boolean = false;
  firstName: string = '';
  lastName: string = '';
  avatar: string = '';
  createdDate: string = '';
  dateOfBirth: string = '';
  experience: string = '';
  height: string = '';
  jerseyNumber: string = '';
  position: string = '';
  status: string = '';
  weight: string = '';
  
  page: string = 'table';

  positions = ['PG: Point Guard', 'SG: Shooting Guard', 'SF: Small Forward', 'PF: Power Forward', 'C: Center'];

  constructor(
    private crudService: CrudPlayerService,
    private toastr: ToastrService,
    public loader: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
      activatedRoute.queryParams.subscribe(params => {
        this.page = params['page'];
        console.log(params);
        if (!this.page) {
          this.page = 'table';
        }

        if (this.page == 'detail' && !this.selectedPlayer) {
          this.switchView('table');
        }
      })
    }

  ngOnInit() {
    this.loader.showLoading();
    this.crudService.getPlayersList().subscribe((data: Player[]) => {
      this.players = data;
      this.filteredPlayers = this.players;
      if (this.players.length == 0) {
        this.page = 'empty';
      }
      this.loader.hideLoading();
      console.log(this.players); // Logging to check if the data is received
    });

    this.loader.showLoading();
    this.crudService.getPlayerStatisticsList().subscribe((data: PlayerStatistics[]) => {
      this.playerStatistics = data;
      if (this.playerStatistics.length == 0) {
        this.page = 'empty';
      }
      this.loader.hideLoading();
      console.log(this.playerStatistics);  
    })
  }

  onPlayerClick(player: Player,playerId: string ) {
    console.log('Clicked Player'+player.firstName);
    this.selectedPlayer = player;
    this.isDetailVisible = true;
    this.selectedPlayerId = playerId;
    this.selectedPlayerStatistics = this.playerStatistics.find((stats) => player.playerStatisticId == stats.id);
    console.log("Stat ID: " + this.selectedPlayerStatistics.id);
    this.switchView('detail');
  }
  
  switchView(view: string) {
    this.filteredPlayers = this.players;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: view
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });
  }

  updatePlayer(player: Player) {
    this.crudService.updatePlayer(this.selectedPlayerId, this.selectedPlayer).then(() => {
      alert('Player updated successfully')
      this.isDetailVisible=false;
    }, (error) => {
      // Handle the error scenario here
      alert(error);
    });
  }

  updatePlayerStatistics() {
    this.crudService.updateStatistics(this.selectedPlayerStatistics.id, this.selectedPlayerStatistics).then(() => {
      alert('Statistics update succesfully');
    })
  }

  onSubmit() {
    const player: Player = {
      firstName: this.firstName,
      lastName: this.lastName,
      dateOfBirth: this.dateOfBirth,
      avatar: this.avatar,
      createdDate: this.createdDate,
      experience: this.experience,
      height: this.height,
      jerseyNumber: this.jerseyNumber,
      position: this.position,
      status: this.status,
      weight: this.weight,
      playerStatisticId: ""
    };

    this.loader.showLoading();
    this.crudService.addPlayer(player).then(() => {
      alert('Player added successfully');
      this.isFormVisible = false;
      this.loader.hideLoading();
      this.switchView('table');
    });
  }

  confirmDelete(selectedPlayer: any) {
    const confirmation = window.confirm('Are you sure you want to delete this player?');
    if (confirmation) {
      // Delete the selected player
      this.crudService.deletePlayer(selectedPlayer.id).then(()=>{
        alert("Player deleted successfully");
        this.switchView('table');
      }).catch((error)=>{
        alert("Error deleting player");
      });
    }
  }


  // Component logic
  showForm(): void {
  this.isFormVisible = !this.isFormVisible; // Toggle the form visibility
  }


  hideForm() {
    this.isFormVisible = false;
  }

  getURL(ref: Reference, parameter: string) {
    ref.getDownloadURL().then((url) => {
      console.log(url);
      switch (parameter) {
        case 'selected':
          this.selectedPlayer.avatar = url;
          break;

        case 'new':
          this.avatar = url;
          break;
      }
    })
  }

  filterList(searchTerm: string) {
    this.filteredPlayers = this.players.filter((player) => {
      return player.firstName.toLowerCase().includes(searchTerm.toLowerCase()) || player.lastName.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }
}
