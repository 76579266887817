import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Court } from './court';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CrudCourtService {
  private courtsCollection: AngularFirestoreCollection<Court>;
  private courts: Observable<Court[]>;
  
  constructor(private afs: AngularFirestore) {
    this.courtsCollection = afs.collection('Courts');
    this.courts = this.courtsCollection.valueChanges({idField: 'id'});
  }

  addCourt(court: Court) {
    return this.courtsCollection.add(court);
  }

  getCourtsList(): Observable<Court[]> {
    return this.courts;
  }

  updateCourt(id: string, court: Court) {
    this.courtsCollection.doc(id).update(court);
  }

  deleteCourt(id: string): Promise<void> {
    return this.courtsCollection.doc(id).delete();
  }
}
