<div [ngSwitch]="page">
    <div *ngSwitchCase="'detail'" class="p-4">
        <button type="button" class="btn btn-tertiary" mdbRipple (click)="switchView('table')">
            <i class='bx bx-arrow-back'></i>
            <span>Back to competitions</span>
        </button>

        <h3>Update Competition</h3>
        <form (ngSubmit)="updateCompetition(selectedCompetition)">
            <app-image-upload [src]="selectedCompetition.competitionImage" (refEvent)="getURL($event, 'selectedComp')"></app-image-upload>
            
            <label class="d-block">Competition Name</label>
            <input type="text" [(ngModel)]="selectedCompetition.competitionName" name="competitionName" class="form-input">
            
            <label class="d-block">Start Date</label>
            <input type="date" [(ngModel)]="selectedCompetition.startDate" name="startDate" class="form-input">

            <label class="d-block">End Date</label>
            <input type="date" [(ngModel)]="selectedCompetition.endDate" name="endDate" class="form-input">

            <label class="d-block">Host</label>
            <input type="text" [(ngModel)]="selectedCompetition.hostId" name="hostId" class="form-input">

            <label class="d-block">Is Approved</label>
            <input type="checkbox" [(ngModel)]="selectedCompetition.isApproved" name="isApproved">

            <label class="d-block">Winner</label>
            <ng-select
                name="winnerTeamId"
                placeholder="Select Winner"
                [(ngModel)]="selectedCompetition.winnerTeamId"
                class="w-50">
                <ng-option *ngFor="let team of teams" [value]="team.id">{{ team.teamName }}</ng-option>
            </ng-select>

            <label class="d-block">Runner-Up</label>
            <ng-select
                name="runnerUpTeamId"
                placeholder="Select Runner-Up"
                [(ngModel)]="selectedCompetition.runnerUpTeamId"
                class="w-50">
                <ng-option *ngFor="let team of teams" [value]="team.id">{{ team.teamName }}</ng-option>
            </ng-select>

            <label class="d-block">Games</label>
            <ng-select
                name="gameIds"
                placeholder="Select Games"
                [multiple]="true"
                [(ngModel)]="selectedCompetition.gameIds"
                class="w-50">
                <ng-option *ngFor="let game of games" [value]="game.id">{{ getTeam(game.team1Id).teamName }} vs {{ getTeam(game.team2Id).teamName }}</ng-option>
            </ng-select>

            <label class="d-block">Trophy</label>
            <app-image-upload [src]="selectedCompetition.trophyImage" (refEvent)="getURL($event, 'selectedTrophy')"></app-image-upload>
            {{selectedCompetition.trophyImage}}
            <input type="text" [(ngModel)]="selectedCompetition.trophyName" name="trophyName" class="form-input mt-2">

            <div class="d-block">
                <button type="button" class="btn btn-danger" (click)="confirmDelete(selectedCompetition)">Delete</button>

                <button type="submit" class="btn btn-info ms-2">Update</button>
            </div>
            
        </form>
    </div>

    <div *ngSwitchCase="'table'" class="p-4">
        <div *ngIf="!(loader.isLoading$ | async)">
            <app-search-bar label="Search Competitions" (onChange)="searchTerm.next($event)" (onFilter)="filter.next($event)" [filters]="['Current', 'Upcoming', 'Past']"></app-search-bar>
            <table class="table table-striped table-hover">
                <thead>
                    <th scope="col">Competition Name</th>
                    <th scope="col">Start Date</th>
                    <th>End date</th>
                    <th scope="col">Trophy</th>
                    <th scope="col">Approved</th>
                </thead>

                <tbody class="table-group-divider table-divider-color divider-color">
                    <tr *ngFor="let competition of filteredCompetitions " (click)="onCompetitonClick(competition)" scope="row">
                        <td>
                            <img [src]="competition.competitionImage">
                            {{competition.competitionName}}
                        </td>
                        <td>{{ competition.startDate }}</td>
                        <td>{{ competition.endDate }}</td>
                        <td>
                            <img [src]="competition.trophyImage">
                            {{ competition.trophyName }}
                        </td>
                        <td>{{ competition.isApproved }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="mx-auto w-25 mt-5" *ngIf="loader.isLoading$ | async">
            <div class="spinner-border text-warning" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="'empty'" class="p-4">
        <app-empty></app-empty>
    </div>
    
    <div class="floating-button-container">
        <button class="float" (click)="switchView(page == 'form' ? 'table' : 'form')">
        <span class="add-icon">+</span>
        </button>
    </div>
    
    <div *ngSwitchCase="'form'" class="p-4">
        <div class="text-center" *ngIf="loader.isLoading$ | async">
            <div class="spinner-border text-warning" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <form (ngSubmit)="onSubmit()">
            <div class="form-group">
                <app-image-upload [src]="newCompetition.competitionImage" (refEvent)="getURL($event, 'newComp')"></app-image-upload>
                
                <label class="d-block">Competition Name</label>
                <input type="text" [(ngModel)]="newCompetition.competitionName" name="competitionName" class="form-input">

                <label class="d-block">Start Date</label>
                <input type="date" [(ngModel)]="newCompetition.startDate" name="startDate" class="form-input">

                <label class="d-block">End Date</label>
                <input type="date" [(ngModel)]="newCompetition.endDate" name="endDate" class="form-input">

                <label class="d-block">Host</label>
                <input type="text" [(ngModel)]="newCompetition.hostId" name="hostId" class="form-input">

                <label class="d-block">Is Approved</label>
                <input type="checkbox" [(ngModel)]="newCompetition.isApproved" name="isApproved">

                <label class="d-block">Winner</label>
                <ng-select
                    name="winnerTeamId"
                    placeholder="Select Winner"
                    [(ngModel)]="newCompetition.winnerTeamId"
                    class="w-50">
                    <ng-option *ngFor="let team of teams" [value]="team.id">{{ team.teamName }}</ng-option>
                </ng-select>

                <label class="d-block">Runner-Up</label>
                <ng-select
                    name="runnerUpTeamId"
                    placeholder="Select Runner-Up"
                    [(ngModel)]="newCompetition.runnerUpTeamId"
                    class="w-50">
                    <ng-option *ngFor="let team of teams" [value]="team.id">{{ team.teamName }}</ng-option>
                </ng-select>

                <label class="d-block">Games</label>
                <ng-select
                    name="gameIds"
                    placeholder="Select Games"
                    [multiple]="true"
                    [(ngModel)]="newCompetition.gameIds"
                    class="w-50">
                    <ng-option *ngFor="let game of games" [value]="game.id">{{ getTeam(game.team1Id).teamName }} vs {{ getTeam(game.team2Id).teamName }}</ng-option>
                </ng-select>

                <label class="d-block">Trophy</label>
                <app-image-upload [src]="newCompetition.trophyImage" (refEvent)="getURL($event, 'newTrophy')"></app-image-upload>
                <input type="text" [(ngModel)]="newCompetition.trophyName" name="trophyName" class="form-input mt-2">
            </div>
            
            <button type="submit" class="btn btn-info ms-2">Submit</button>
        </form>
    </div>
</div>