import { Component, OnInit } from '@angular/core';
import { Booking } from '../shared/booking/booking';
import { Court } from '../shared/court/court';
import { Game } from '../shared/game/game';
import { CrudBookingService } from '../shared/booking/crud-booking.service';
import { CrudGameService } from '../shared/game/crud-game.service';
import { CrudCourtService } from '../shared/court/crud-court.service';
import { error } from 'node:console';
import { CrudTeamService } from '../shared/team/crud-team.service';
import { Team } from '../shared/team/team';
import { LoadingService } from '../shared/loading/loading.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bookings-table',
  templateUrl: './bookings-table.component.html',
  styleUrls: ['./bookings-table.component.css']
})
export class BookingsTableComponent implements OnInit {
  bookings: Booking[];
  courts: Court[];
  games: Game[];
  teams: Team[];

  selectedBooking: Booking;
  newBooking: Booking;

  page: string = 'table';

  constructor(
    private crudService: CrudBookingService,
    private crudGameService: CrudGameService,
    private crudCourtService: CrudCourtService,
    private crudTeamService: CrudTeamService,
    public loader: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    activatedRoute.queryParams.subscribe(params => {
      this.page = params['page'];
      console.log(params);
      if (!this.page) {
        this.page = 'table';
      }

      if (this.page == 'detail' && !this.selectedBooking) {
        this.switchView('table');
      }
    })

    this.selectedBooking = {
      courtId: '',
      gameId: '',
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: ''
    }

    this.newBooking = {
      courtId: '',
      gameId: '',
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: ''
    }
  }

  ngOnInit() {
    this.loader.showLoading();
    this.crudService.getBookingsList().subscribe((data: Booking[]) => {
      this.bookings = data;
      if (this.bookings.length == 0) {
        this.page = 'empty';
      }
      this.loader.hideLoading();
    });

    this.loader.showLoading();
    this.crudGameService.getGamesList().subscribe((data: Game[]) => {
      this.games = data;
      if (this.games.length == 0) {
        this.page = 'empty';
      }
      this.loader.hideLoading();
    });

    this.loader.showLoading();
    this.crudCourtService.getCourtsList().subscribe((data: Court[]) => {
      this.courts = data;
      if (this.courts.length == 0) {
        this.page = 'empty';
      }
      this.loader.hideLoading();
    });

    this.loader.showLoading();
    this.crudTeamService.getTeamsList().subscribe((data: Team[]) => {
      this.teams = data;
      if (this.teams.length == 0) {
        this.page = 'empty';
      }
      this.loader.hideLoading();
    })
  }

  onBookingClick(booking: Booking) {
    console.log('Clicked ' + booking.id);
    this.selectedBooking = booking;
    this.switchView('detail');
  }

  switchView(view: string) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: view
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });
  }

  getGame(id: string) {
    return this.games.find((game) => game.id == id);
  }

  getTeam(id: string) {
    return this.teams.find((team) => team.id == id);
  }

  getCourt(id: string) {
    return this.courts.find((court) => court.id == id);
  }

  onSubmit() {
    this.loader.showLoading();
    this.crudService.addBooking(this.selectedBooking).then(() => {
      alert("Booking added");
      this.loader.hideLoading();
      this.switchView('table');
    });
    
  }

  updateBooking() {
    this.crudService.updateBooking(this.selectedBooking.id, this.selectedBooking);
    alert("Booking updated");
  }

  confirmDelete() {
    const confirmation = window.confirm('Are you sure you want to delete this user?');
    if (confirmation) {
      this.crudService.deleteBooking(this.selectedBooking.id).then(() => {
        alert("Booking deleted");
        this.switchView('table');
      });
    }
  }
}
