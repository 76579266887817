export const environment = {
    production: false,
    firebaseConfig: {
        apiKey: "AIzaSyAAl7-kDYMLU38T_W1aupwbmuRqWpVPJD0",
        authDomain: "the-zone-e2704.firebaseapp.com",
        databaseURL: "https://the-zone-e2704-default-rtdb.firebaseio.com",
        projectId: "the-zone-e2704",
        storageBucket: "the-zone-e2704.appspot.com",
        messagingSenderId: "740999421278",
        appId: "1:740999421278:web:26e7fa3c507400030603cf",
        measurementId: "G-QZS0YPJQ8P"
    }
  };
  