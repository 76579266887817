<div [ngSwitch]="page">
    <div *ngSwitchCase="'detail'" class="p-4">
        <button type="button" class="btn btn-tertiary" mdbRipple (click)="switchView('table')">
            <i class='bx bx-arrow-back'></i>
            <span>Back to bookings</span>
        </button>

        <h3>Update Booking</h3>
        <form (ngSubmit)="updateBooking()">
            <label class="d-block">Court</label>
            <ng-select
                name="courtId"
                placeholder="Select Court"
                [(ngModel)]="selectedBooking.courtId"
                class="w-50">
                <ng-option *ngFor="let court of courts" [value]="court.id">{{ court.name }}</ng-option>
            </ng-select>

            <label class="d-block">Game</label>
            <ng-select
                name="gameId"
                placeholder="Select Game"
                [(ngModel)]="selectedBooking.gameId"
                class="w-50">
                <ng-option *ngFor="let game of games" [value]="game.id">{{ getTeam(game.team1Id).teamName }} - {{ getTeam(game.team2Id).teamName }}</ng-option>
            </ng-select>

            <label class="d-block">Start Date</label>
            <input type="date" class="form-input" [(ngModel)]="selectedBooking.startDate" name="startDate">

            <label class="d-block">End Date</label>
            <input type="date" class="form-input" [(ngModel)]="selectedBooking.endDate" name="endDate">

            <label class="d-block">Start Time</label>
            <input type="time" class="form-input" [(ngModel)]="selectedBooking.startTime" name="startTime">

            <label class="d-block">End Time</label>
            <input type="time" class="form-input" [(ngModel)]="selectedBooking.endTime" name="endTime">
        
            <div class="form-group"></div>
            <button type="button" class="btn btn-danger" (click)="confirmDelete()">Delete</button>

            <button type="submit" class="btn btn-info ms-2">Update</button>
        </form>
    </div>

    <div *ngSwitchCase="'table'" class="p-4">
        <table class="table table-striped table-hover" *ngIf="!(loader.isLoading$ | async)">
            <thead>
                <th scope="col">Start Date</th>
                <th>End date</th>
                <th>Time</th>
                <th scope="col">Court</th>
            </thead>

            <tbody class="table-group-divider table-divider-color divider-color">
                <tr *ngFor="let booking of bookings " (click)="onBookingClick(booking)" scope="row">
                    <td>{{ booking.startDate }}</td>
                    <td>{{ booking.endDate }}</td>
                    <td>{{ booking.startTime }} - {{ booking.endTime }}</td>
                    <td>{{ getCourt(booking.courtId).name }}</td>
                </tr>
            </tbody>
        </table>

        <div class="mx-auto w-25 mt-5" *ngIf="loader.isLoading$ | async">
            <div class="spinner-border text-warning" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        
    </div>

    <div *ngSwitchCase="'empty'">
        <app-empty></app-empty>
    </div>

    <div class="floating-button-container">
        <button class="float" (click)="switchView(page == 'form' ? 'table' : 'form')">
        <span class="add-icon">+</span>
        </button>
    </div>

    <div *ngSwitchCase="'form'" class="form-container">
        <div class="text-center" *ngIf="loader.isLoading$ | async">
            <div class="spinner-border text-warning" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <form  (ngSubmit)="onSubmit()">
            <label class="d-block">Court</label>
            <ng-select
                name="courtId"
                placeholder="Select Court"
                [(ngModel)]="newBooking.courtId"
                class="w-50">
                <ng-option *ngFor="let court of courts" [value]="court.id">{{ court.name }}</ng-option>
            </ng-select>

            <label class="d-block">Game</label>
            <ng-select
                name="gameId"
                placeholder="Select Game"
                [(ngModel)]="newBooking.gameId"
                class="w-50">
                <ng-option *ngFor="let game of games" [value]="game.id">{{ getTeam(game.team1Id).teamName }} - {{ getTeam(game.team2Id).teamName }}</ng-option>
            </ng-select>

            <label class="d-block">Start Date</label>
            <input type="date" class="form-input" [(ngModel)]="newBooking.startDate" name="startDate">

            <label class="d-block">End Date</label>
            <input type="date" class="form-input" [(ngModel)]="newBooking.endDate" name="endDate">

            <label class="d-block">Start Time</label>
            <input type="time" class="form-input" [(ngModel)]="newBooking.startTime" name="startTime">

            <label class="d-block">End Time</label>
            <input type="time" class="form-input" [(ngModel)]="newBooking.endTime" name="endTime">
        
            <div class="form-group"></div>

            <button type="submit" class="btn btn-info ms-2">Submit</button>
        </form>
    </div>
</div>
