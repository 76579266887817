<div [ngSwitch]="page">
    <div *ngSwitchCase="'detail'" class="form-container">
        <button type="button" class="btn btn-tertiary" mdbRipple (click)="switchView('table')">
            <i class='bx bx-arrow-back'></i>
            <span>Back to courts</span>
        </button>
        <h3>Update Court</h3>
        <form (ngSubmit)="updateCourt()">
            <div class="form-group">
                <label class="d-block">Address</label>
                <input type="text" name="address" [(ngModel)]="selectedCourt.address" class="form-input">
            
                <label class="d-block">Is Approved</label>
                <input type="checkbox" [(ngModel)]="selectedCourt.availability" name="availability">
            
                <label class="d-block">Court Name</label>
                <input type="text" name="name" [(ngModel)]="selectedCourt.name" class="form-input">
            
                <app-image-upload [src]="selectedCourt.pictureUrl"></app-image-upload>

                <label class="d-block">Size</label>
                <ng-select
                    [(ngModel)]="selectedCourt.size"
                    name="size"
                    class="w-50">
                    <ng-option *ngFor="let size of courtSizes" [value]="size">{{size}}</ng-option>
                </ng-select>

                <label class="d-block">Type</label>
                <ng-select
                    [(ngModel)]="selectedCourt.type"
                    name="type"
                    class="w-50">
                    <ng-option *ngFor="let type of courtTypes" [value]="type">{{type}}</ng-option>
                </ng-select>
            </div>

            <button type="button" class="btn btn-danger" (click)="confirmDelete()">Delete</button>

            <button type="submit" class="btn btn-info ms-2">Update</button>
        </form>
    </div>

    <div *ngSwitchCase="'empty'" class="p-4">
        <app-empty></app-empty>
    </div>
    
    <div class="floating-button-container">
        <button class="float" (click)="switchView(page == 'form' ? 'table' : 'form')">
        <span class="add-icon">+</span>
        </button>
    </div>

    <div *ngSwitchCase="'table'" class="p-4">
        <div *ngIf="!(loader.isLoading$ | async)">
            <app-search-bar label="Search Courts" (onChange)="searchTerm.next($event)" (onFilter)="filter.next($event)" [filters]="courtSizes">
            </app-search-bar>
            <table class="table table-striped table-hover">
                <thead>
                    <th scope="col">Name</th>
                    <th scope="col">Address</th>
                    <th scope="col">Size</th>
                    <th scope="col">Tye</th>
                </thead>
    
                <tbody class="table-group-divider table-divider-color divider-color">
                    <tr *ngFor="let court of filteredCourts " (click)="onCourtClick(court)" scope="row">
                        <td>
                            <img [src]="court.pictureUrl">
                            {{ court.name }}
                        </td>
                        <td>{{ court.address }}</td>
                        <td>{{ court.size }}</td>
                        <td>{{ court.type }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="mx-auto w-25 mt-5" *ngIf="loader.isLoading$ | async">
            <div class="spinner-border text-warning" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="'form'" class="form-container">
        <div class="text-center" *ngIf="loader.isLoading$ | async">
            <div class="spinner-border text-warning" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        
        <form (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label class="d-block">Address</label>
                <input type="text" name="address" [(ngModel)]="newCourt.address" class="form-input">
            
                <label class="d-block">Is Approved</label>
                <input type="checkbox" [(ngModel)]="newCourt.availability" name="availability">
            
                <label class="d-block">Court Name</label>
                <input type="text" name="name" [(ngModel)]="newCourt.name" class="form-input">
            
                <app-image-upload [src]="newCourt.pictureUrl"></app-image-upload>

                <label class="d-block">Size</label>
                <ng-select
                    [(ngModel)]="newCourt.size"
                    name="size"
                    class="w-50">
                    <ng-option *ngFor="let size of courtSizes" [value]="size">{{size}}</ng-option>
                </ng-select>

                <label class="d-block">Type</label>
                <ng-select
                    [(ngModel)]="newCourt.type"
                    name="type"
                    class="w-50">
                    <ng-option *ngFor="let type of courtTypes" [value]="type">{{type}}</ng-option>
                </ng-select>
            </div>

            <button type="submit" class="btn btn-info ms-2">Update</button>
        </form>
    </div>
</div>