import { Component, OnInit } from '@angular/core';
import { Brand } from '../shared/brand/Brand';
import { CrudBrandService } from '../shared/brand/crud-brand.service';
import { ToastrService } from 'ngx-toastr';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Reference } from '@angular/fire/compat/storage/interfaces';
import { LoadingService } from '../shared/loading/loading.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-brands-table',
  templateUrl: './brands-table.component.html',
  styleUrls: ['./brands-table.component.css']
})
export class BrandsTableComponent implements OnInit{

  brands: Brand[];
  filteredBrands: Brand[];
  selectedBrand: Brand;
  selectedBrandId: string;

  brandName: string;
  brandImage: string;

  page: string = 'table';

  constructor(
    private crudService: CrudBrandService,
    private toastr: ToastrService,
    private storage: AngularFireStorage,
    public loader: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    activatedRoute.queryParams.subscribe(params => {
      this.page = params['page'];
      console.log(params);
      if (!this.page) {
        this.page = 'table';
      }

      if (this.page == 'detail' && !this.selectedBrand) {
        this.switchView('table');
      }
    })
  }

  ngOnInit(): void {
    this.loader.showLoading();
    this.crudService.getBrandsList().subscribe((data: Brand[]) => {
      this.brands = data;
      this.filteredBrands = this.brands;
      if (this.brands.length == 0) {
        this.page = 'empty';
      }
      this.loader.hideLoading();
      console.log(this.brands);
    });
  }

  onBrandClick(brand: Brand, brandId: string) {
    console.log('Clicked brand: ' + brand.brandName);
    this.selectedBrand = brand;
    this.selectedBrandId = brandId;
    this.switchView('detail');
  }

  switchView(view: string) {
    this.filteredBrands = this.brands;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: view
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });
  }

  updateBrand(brand: Brand) {
    this.crudService.updateBrand(this.selectedBrandId, this.selectedBrand).then(() => {
      alert('Brand updated successfully');
      this.switchView('table');
    }, (error) => {
      alert('Error');
    })
  }

  onSubmit() {
    const brand: Brand = {
      brandName: this.brandName,
      brandImage: this.brandImage
    }

    this.loader.showLoading();
    this.crudService.addBrand(brand).then(() => {
      alert('Brand added successfully');
      this.switchView('table');
      this.loader.hideLoading();
    })
  }

  confirmDelete() {
    const confirmation = window.confirm('Are you sure you want to delete this brand?');
    if(confirmation) {
      this.crudService.deleteBrand(this.selectedBrandId).then(() => {
        alert('Brand deleted successfully');
        this.switchView('table');
      }).catch(() => alert('Error'));
    }
  }

  uploadFile(ev: Event) {
    alert("Uploading image");
    const file: File = (ev.target as HTMLInputElement).files[0];
    const filePath = Date.now() + file.name;
    this.storage.upload(filePath, file).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((url) => this.selectedBrand.brandImage = url );
    }, (error) => console.log(error));
  }

  getURL(ref: Reference) {
    ref.getDownloadURL().then((url) => {
      console.log(url);
      this.selectedBrand.brandImage = url;
      this.brandImage = url;
    })
  }

  filterList(searchTerm: string) {
    this.filteredBrands = this.brands.filter((brand) => {
      return brand.brandName.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }
}
