<!DOCTYPE html>
<html>
  <head>
    <title>Welcome to the Zone OE</title>
    <!-- Include Bootstrap CSS -->
    <link href="https://stackpath.bootstrapcdn.com/bootstrap/5.1.3/css/bootstrap.min.css" rel="stylesheet">
  </head>
  <body class="bg-image">
    <div class="authBlock col-8 col-md-4 mx-auto p-1 p-md-4 text-dark">
      <div class="mx-auto w-25">
        <img src="../../assets/images/brand_logo.png" class="img-fluid" alt="..." />
      </div>
      <p class="text-center fs-5" style="color: #FFFFE6;">Welcome to The Zone Admin Portal</p>

      <mdb-form-control class="my-3 w-50 mx-auto">
        <input mdbInput type="text" id="userName" class="form-control input" #userName/>
        <label mdbLabel class="form-label inputLabel" for="userName" style="color: #ED7433;">Username</label>
      </mdb-form-control>

      <mdb-form-control class="my-3 w-50 mx-auto">
        <input mdbInput type="password" id="password" class="form-control input" #userPassword/>
        <label mdbLabel class="form-label" for="password" style="color: #ED7433;">Password</label>
      </mdb-form-control>

      <p class="text-danger mx-auto w-50" *ngIf="error">Incorrect email and password</p>

      <div class="text-center" *ngIf="loader.isLoading$ | async">
        <div class="spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <!-- Centered button -->
      <div class="w-50 mt-5 mx-auto">
        <button type="button" class="btn submitButton w-100 mx-auto" mdbRipple
          (click)="signIn(userName.value, userPassword.value)">Log in</button>
      </div>
      
    </div>
    <!-- Include Bootstrap JS -->
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/5.1.3/js/bootstrap.bundle.min.js"></script>
  </body>
  <!-- <footer class="footer">
    <div class="footer-content">
      <p>© 2023 The Zone OE. All rights reserved.</p>
    </div>
  </footer> -->
</html>
