import { Component, OnInit } from '@angular/core';
import { CrudCoachService } from '../shared/coach/crud-coaches.service';
import { Observable } from 'rxjs';
import { Coach } from '../shared/coach/coach';
import { ToastrService } from 'ngx-toastr';
import { Reference } from '@angular/fire/compat/storage/interfaces';
import { LoadingService } from '../shared/loading/loading.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-coach-table',
  templateUrl: './coach-table.component.html',
  styleUrls: ['./coach-table.component.css']
})
export class CoachsTableComponent implements OnInit {
  Coachs: Coach[];
  filteredCoaches: Coach[];
  isFormVisible: boolean;
  newCoach: Coach;
  selectedCoach: Coach; // Store the selected Coach
  isDetailVisible = false;
  selectedCoachId: string | null = null;
  isModalVisible: boolean = false;

  //showForm: boolean = false;
  firstName: string = '';
  lastName: string = '';
  age: string = '';
  avatar: string = '';
  experience: string = '';
  startDate: string = '';
  endDate: string = '';

  page: string = 'table';

  constructor(
    private crudService: CrudCoachService,
    private toastr: ToastrService,
    public loader: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    activatedRoute.queryParams.subscribe(params => {
      this.page = params['page'];
      console.log(params);
      if (!this.page) {
        this.page = 'table';
      }

      if (this.page == 'detail' && !this.selectedCoach) {
        this.switchView('table');
      }
    })
  }

  ngOnInit() {
    this.loader.showLoading();
    this.crudService.getCoachsList().subscribe((data: Coach[]) => {
      this.Coachs = data;
      this.filteredCoaches = this.Coachs;
      if (this.Coachs.length == 0) {
        this.page = 'empty';
      }
      this.loader.hideLoading();
      console.log(this.Coachs); // Logging to check if the data is received
    });

    this.selectedCoach = {
      firstName: '',
      lastName: '',
      age: '',
      avatar: '',
      experience: '',
      startDate: '',
      endDate: ''
    };
  }

  onCoachClick(Coach: Coach,CoachId: string ) {
    console.log('Clicked Coach'+Coach.firstName);
    this.selectedCoach = Coach;
    this.selectedCoachId = CoachId;
    this.switchView('detail');
  }
  
  switchView(view: string) {
    this.filteredCoaches = this.Coachs;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: view
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });
  }

  updateCoach(Coach: Coach) {
    this.crudService.updateCoach(this.selectedCoachId, this.selectedCoach).then(() => {
      alert('Coach updated successfully')
      this.isDetailVisible=false;
    }, (error) => {
      // Handle the error scenario here
    });
  }

  onSubmit() {
    const Coach: Coach = {
      firstName: this.firstName,
      lastName: this.lastName,
      age: "",
      avatar: "",
      startDate: "",
      endDate: "",
      experience: ""
    };
    this.loader.showLoading();
    this.crudService.addCoach(Coach).then(() => {
      alert('Coach added successfully');
      this.isFormVisible = false;
      this.loader.hideLoading();
    });
  }

  confirmDelete(selectedCoach: any) {
    const confirmation = window.confirm('Are you sure you want to delete this user?');
    if (confirmation) {
      // Delete the selected Coach
      this.crudService.deleteCoach(selectedCoach.id).then(()=>{
        alert("Coach deleted successfully");
        this.switchView('table');
      }).catch((error)=>{
        alert("Error deleting user")
      });
    }
  }


  // Component logic
  showForm(): void {
  this.isFormVisible = !this.isFormVisible; // Toggle the form visibility
  }


  hideForm() {
    this.isFormVisible = false;
  }
  
  getURL(ref: Reference, parameter: string) {
    ref.getDownloadURL().then((url) => {
      console.log(url);
      switch (parameter) {
        case 'selected':
          this.selectedCoach.avatar = url;
          break;

        case 'new':
          this.avatar = url;
          break;
      }
    })
  }

  filterList(searchTerm: string) {
    this.filteredCoaches = this.Coachs.filter((coach) => {
      return coach.firstName.toLowerCase().includes(searchTerm.toLowerCase()) || coach.lastName.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }
}

