<div [ngSwitch]="page">
    <div *ngSwitchCase="'detail'" class="form-container">
        <button type="button" class="btn btn-tertiary" mdbRipple (click)="switchView('table')">
            <i class='bx bx-arrow-back'></i>
            <span>Back to users</span>
        </button>
        <h3>Update User</h3>
        <form (ngSubmit)="updateUser()">
            <div class="form-group">
                <label class="d-block">Full Name</label>
                <input type="text" name="fullName" [(ngModel)]="selectedUser.fullName" class="form-input">

                <label class="d-block">Address</label>
                <input type="text" name="address" [(ngModel)]="selectedUser.address" class="form-input">
            
                <label class="d-block">Cellphone Number</label>
                <input type="text" name="cellPhoneNumber" [(ngModel)]="selectedUser.cellPhoneNumber" class="form-input">
            
                <label class="d-block">Date of Birth</label>
                <input type="date" name="dateOfBirth" [(ngModel)]="selectedUser.dateOfBirth" class="form-input">
            
                <label class="d-block">Email</label>
                <input type="email" name="email" [(ngModel)]="selectedUser.email" class="form-input">
            
                <label class="d-block">Frequency of Player</label>
                <ng-select
                    name="frequencyOfPlayer"
                    [(ngModel)]="selectedUser.frequencyOfPlay"
                    class="w-50">
                    <ng-option *ngFor="let frequency of frequencyOfPlayOptions" [value]="frequency">{{ frequency }}</ng-option>
                </ng-select>
            
                <label class="d-block">Type of User</label>
                <ng-select
                    name="typeOfUser"
                    [(ngModel)]="selectedUser.typeOfUser"
                    class="w-50">
                    <ng-option *ngFor="let type of userTypes" [value]="type">{{ type }}</ng-option>
                </ng-select>
            </div>

            <button type="button" class="btn btn-tertiary d-block" mdbRipple (click)="sendResetEmail()">Send password reset email</button>

            <button type="button" class="btn btn-danger" (click)="confirmDelete()">Delete</button>

            <button type="submit" class="btn btn-info ms-2">Update</button>
        </form>
    </div>

    <div *ngSwitchCase="'table'" class="p-4">
        <div *ngIf="!(loader.isLoading$ | async)">
            <app-search-bar label="Search Users" (onChange)="filterList($event)"></app-search-bar>
            <table class="table table-striped table-hover">
                <thead>
                    <th scope="col">Full Name</th>
                    <th scope="col">Address</th>
                    <th scope="col">Email</th>
                    <th scope="col">Type of User</th>
                </thead>
    
                <tbody class="table-group-divider table-divider-color divider-color">
                    <tr *ngFor="let user of filteredUsers " (click)="onUserClick(user)" scope="row">
                        <td>{{user.fullName}}</td>
                        <td>{{user.address}}</td>
                        <td>{{user.email}}</td>
                        <td>{{user.typeOfUser}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="mx-auto w-25 mt-5" *ngIf="loader.isLoading$ | async">
            <div class="spinner-border text-warning" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="'empty'" class="p-4">
        <app-empty></app-empty>
    </div>
</div>