import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { Booking } from './booking';

@Injectable({
  providedIn: 'root'
})
export class CrudBookingService {
  private bookingsCollection: AngularFirestoreCollection<Booking>;
  bookings: Observable<Booking[]>;

  constructor(private afs: AngularFirestore) {
    this.bookingsCollection = afs.collection('Bookings');
    this.bookings = this.bookingsCollection.valueChanges({idField: 'id'});
  }

  addBooking(booking: Booking) {
    return this.bookingsCollection.add(booking);
  }

  getBookingsList() {
    return this.bookings;
  }

  updateBooking(id: string, booking: Booking) {
    this.bookingsCollection.doc(id).update(booking);
  }

  deleteBooking(id: string): Promise<void> {
    return this.bookingsCollection.doc(id).delete();
  }
}
