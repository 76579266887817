<div [ngSwitch]="page">
    <div *ngSwitchCase="'detail'" class="form-container">
      <button type="button" class="btn btn-tertiary" mdbRipple (click)="switchView('table')">
        <i class='bx bx-arrow-back'></i>
        <span>Back to coaches</span>
      </button>
    <h3>Update Coach</h3>
    <form (ngSubmit)="updateCoach(selectedCoach)">
      <div class="form-group">
        <label>Personal Information</label>
        <app-image-upload [src]="selectedCoach.avatar" (refEvent)="getURL($event, 'selected')"></app-image-upload>

        <div class="form-inline mt-2">
          <div class="me-2">
            <label>First Name</label>
            <input type="text" placeholder="First Name" [(ngModel)]="selectedCoach.firstName" name="firstName" class="form-input">
          </div>
          
          <div>
            <label>Last Name</label>
            <input type="text" placeholder="Last Name" [(ngModel)]="selectedCoach.lastName" name="lastName" class="form-input">
          </div>
        </div>
    </div>

    <!-- Group 2 -->
    <div class="form-group">
      <div class="form-inline">
        <div class="me-2">
          <label>Age</label>
          <input type="number" placeholder="Age" [(ngModel)]="selectedCoach.age" name="age" class="form-input">
        </div>
        
        <div>
          <label>Experience (Years)</label>
          <input type="number" placeholder="Experience" [(ngModel)]="selectedCoach.experience" name="experience" class="form-input">
        </div>            
      </div>

      <div class="form-inline">
        <div class="me-2">
          <label>Start Date</label>
          <input type="date" placeholder="Start Date" [(ngModel)]="selectedCoach.startDate" name="startDate" class="form-input">
        </div>

        <div>
          <label>End Date</label>
          <input type="date" placeholder="Mobile Number" [(ngModel)]="selectedCoach.endDate" name="endDate" class="form-input">
        </div>              
      </div>
    </div>

        <button type="button" class="btn btn-danger" (click)="confirmDelete(selectedCoach)">Delete</button>

        <button type="submit" class="btn btn-info ms-2">Update</button>
    </form>
</div>

    <div *ngSwitchCase="'table'">
    <div>
      <div *ngIf="!(loader.isLoading$ | async)">
        <app-search-bar label="Search Coaches" (onChange)="filterList($event)"></app-search-bar>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Name & Surname</th>
              <th>Experience</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody class="table-group-divider table-divider-color divider-color">
            <tr *ngFor="let coach of filteredCoaches " (click)="onCoachClick(coach,coach.id)">
              <td>
                <img src="https://secure.gravatar.com/avatar/d09eaad01aea86c51b4f892b4f8abf6f?s=100&d=wavatar&r=g">
                {{ coach.firstName }} {{ coach.lastName }}
              </td>
              <td>{{ coach.experience }}</td>
              <td>{{ coach.startDate }}</td>
              <td>{{ coach.endDate }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mx-auto w-25 mt-5" *ngIf="loader.isLoading$ | async">
        <div class="spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
</div>

<div *ngSwitchCase="'empty'" class="p-4">
  <app-empty></app-empty>
</div>

<div class="floating-button-container">
    <button class="float" (click)="switchView(page == 'form' ? 'table' : 'form')">
      <span class="add-icon">+</span>
    </button>
    <span class="add-Coach-text">Add Coach</span>
  </div>
  

  <div *ngSwitchCase="'form'" class="form-container">
    <div class="text-center" *ngIf="loader.isLoading$ | async">
      <div class="spinner-border text-warning" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <form (ngSubmit)="onSubmit()" class="player-form">
      <div class="form-group">
        <label>Personal Information</label>
        <app-image-upload [src]="selectedCoach.avatar" (refEvent)="getURL($event, 'new')"></app-image-upload>

        <div class="form-inline mt-2">
          <div class="me-2">
            <label>First Name</label>
            <input type="text" placeholder="First Name" [(ngModel)]="firstName" name="firstName" class="form-input">
          </div>
          
          <div>
            <label>Last Name</label>
            <input type="text" placeholder="Last Name" [(ngModel)]="lastName" name="lastName" class="form-input">
          </div>
        </div>
      </div>

    <!-- Group 2 -->
      <div class="form-group">
        <div class="form-inline">
          <div class="me-2">
            <label>Age</label>
            <input type="number" placeholder="Age" [(ngModel)]="age" name="age" class="form-input">
          </div>
        
          <div>
            <label>Experience (Years)</label>
            <input type="number" placeholder="Experience" [(ngModel)]="experience" name="experience" class="form-input">
          </div>            
        </div>

        <div class="form-inline">
          <div class="me-2">
            <label>Start Date</label>
            <input type="date" placeholder="Start Date" [(ngModel)]="startDate" name="startDate" class="form-input">
          </div>

          <div>
            <label>End Date</label>
            <input type="date" placeholder="Mobile Number" [(ngModel)]="endDate" name="endDate" class="form-input">
          </div>              
        </div>
      </div>
      <button type="submit" class="btn btn-info ms-2 w-25">Submit</button>
    </form>
  </div>
</div>
  