import { Injectable } from '@angular/core';
import { Player } from '../player/player';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentReference } from '@angular/fire/firestore';
import { PlayerStatistics } from './playerStatistics';



@Injectable({
  providedIn: 'root',
})
export class CrudPlayerService {
  private playersCollection: AngularFirestoreCollection<Player>;
  private playerStatisticsCollection: AngularFirestoreCollection<PlayerStatistics>;
  players: Observable<Player[]>;
  playerStatistics: Observable<PlayerStatistics[]>;

  constructor(private afs: AngularFirestore) {
    this.playersCollection = this.afs.collection<Player>('Players');
    this.players = this.playersCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Player;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
    this.playerStatisticsCollection = this.afs.collection('PlayerStatistics');
    this.playerStatistics = this.playerStatisticsCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
    this.playerStatistics.subscribe((stats) => console.log(stats));
  }

  // Create Player
  async addPlayer(player: Player) {
    const emptyStats: PlayerStatistics = {
      assists: 0,
      rebounds: 0,
      steals: 0,
      totalPoints: 0
    }
    const ref = await this.playerStatisticsCollection.add(emptyStats);
    player.playerStatisticId = ref.id;
    return await this.playersCollection.add(player);
  }

  // Fetch Single Player Object
  getPlayer(id: string): Observable<Player> {
    return this.playersCollection.doc<Player>(id).valueChanges();
  }

  // Fetch Players List
  getPlayersList(): Observable<Player[]> {
    return this.players;
  }

  // Update Player Object
  updatePlayer(id: string, player: Player): Promise<void> {
    return this.playersCollection.doc<Player>(id).update(player);
  }

  // Delete Player Object
  deletePlayer(id: string): Promise<void> {
    return this.playersCollection.doc<Player>(id).delete();
  }

  getPlayersCount() {
    return this.playersCollection.get().toPromise().then(snapshot => {
      return snapshot.size;
    });
  }

  getPlayerStatisticsList(): Observable<PlayerStatistics[]> {
    return this.playerStatistics;
  }

  updateStatistics(id: string, statistics: PlayerStatistics) {
    return this.playerStatisticsCollection.doc<PlayerStatistics>(id).update(statistics);
  }
}