import { Component, OnInit } from '@angular/core';
import { PlayersTableComponent } from '../players-table/players-table.component';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Player } from '../shared/player/player';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Coach } from '../shared/coach/coach';
import { Fan } from '../shared/fans/fan';
import { Team } from '../shared/team/team';
import { Court } from '../shared/court/court';
import { Booking } from '../shared/booking/booking';
import { Brand } from '../shared/brand/Brand';
import { Competition } from '../shared/competition/competition';
import { Game } from '../shared/game/game';
import { PushNotificationService, Notification } from '../shared/services/push-notification.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  selectedTab: string = 'Dashboard';
  status: boolean = false;
  private playersCollection: AngularFirestoreCollection<Player>;
  private coachesCollection: AngularFirestoreCollection<Coach>;
  private teamsCollection:AngularFirestoreCollection<Team>;
  private courtsCollection:AngularFirestoreCollection<Court>;
  private gamesCollection: AngularFirestoreCollection<Game>;
  private competitionsCollection: AngularFirestoreCollection<Competition>;
  private brandsCollection: AngularFirestoreCollection<Brand>;
  private bookingsCollection: AngularFirestoreCollection<Booking>;

  notifications: Notification[];
  engineNotifications: Notification[];
  appNotifications: Notification[];


  playerCount: number = 0; // Initialize count to zero
  coachCount: number=0;
  teamCount: number=0;
  courtCount: number=0;
  gameCount: number=0;
  competitionCount: number=0;
  brandCount: number=0;
  bookingCount: number=0;

  dashboardData = "This is the Dashboard section. Dummy data goes here.";
  playersData = "This is the Players section. Dummy data goes here.";
  coachesData = "This is the Coaches section. Dummy data goes here.";
  fansData = "This is the Fans section. Dummy data goes here.";
  venuesData = "This is the Venues section. Dummy data goes here.";


  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private push: PushNotificationService,
    private activatedRoute: ActivatedRoute
   ) {
    this.playersCollection = this.afs.collection<Player>('Players');
    this.coachesCollection = this.afs.collection<Coach>('Coaches');
    this.teamsCollection = this.afs.collection<Team>('Teams');
    this.courtsCollection = this.afs.collection<Court>('Courts');
    this.gamesCollection = afs.collection('Games');
    this.competitionsCollection = afs.collection('Competitions');
    this.brandsCollection = afs.collection<Brand>('Brands');
    this.bookingsCollection = afs.collection('Bookings');
    push.getNotificationsList().subscribe((data) => {
      this.notifications = data;
      this.engineNotifications = data.filter((notification) => notification.triggeredFrom == "OE");
      this.appNotifications = data.filter((notification) => notification.triggeredFrom == "App");
    })
  }


  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.checkActiveRoute();
    });

    this.checkActiveRoute();

    this.playersCollection.valueChanges().subscribe((players: any) => {
      this.playerCount = players.length;
    });

    this.coachesCollection.valueChanges().subscribe((coaches: any) => {
      this.coachCount = coaches.length;
    });

    this.teamsCollection.valueChanges().subscribe((teams: any) => {
      this.teamCount = teams.length;
    });

    this.courtsCollection.valueChanges().subscribe((courts: any) => {
      this.courtCount = courts.length;
    });

    this.gamesCollection.valueChanges().subscribe((game: any) => {
      this.gameCount = game.length;
    });

    this.competitionsCollection.valueChanges().subscribe((competitions: any) => {
      this.competitionCount = competitions.length;
    });

    this.brandsCollection.valueChanges().subscribe((brands: any) => {
      this.brandCount = brands.length;
    });

    this.bookingsCollection.valueChanges().subscribe((bookings: any) => {
      this.bookingCount = bookings.length;
    });

  }

  checkActiveRoute(): void {
    let child = this.activatedRoute.firstChild;
    console.log(child.snapshot.url);

    if (child.snapshot.url.length > 0) {
      const path = child.snapshot.url[0].path;
      this.selectedTab = path.charAt(0).toUpperCase() + path.slice(1);
    }
  }

  changeTab(tabName: string) {
    this.selectedTab = tabName;
  }

  logout() {
    this.afAuth.signOut().then(() => {
      // Redirect to the sign-in page
      this.router.navigate(['/sign-in']);
    }).catch((error) => {
      // Handle any errors that occur during the logout process
      console.error('Logout Error: ', error);
    });
  }
  

  addToggle() {
    this.status = !this.status;
  }
}
