<div [ngSwitch]="page">
    <div *ngSwitchCase="'detail'" class="form-container">
        <button type="button" class="btn btn-tertiary" mdbRipple (click)="switchView('table')">
            <i class='bx bx-arrow-back'></i>
            <span>Back to brands</span>
        </button>
        <h3>Update Brand</h3>
        <form (ngSubmit)="updateBrand(selectedBrand)">
            <div class="form-group">
                <label class="d-block mb-2">Brand Information</label>
                <app-image-upload [src]="selectedBrand.brandImage" (refEvent)="getURL($event)"></app-image-upload>
                <input type="text" placeholder="Brand Name" [(ngModel)]="selectedBrand.brandName" name="brandName"
                    class="form-input w-50 mt-2">
            </div>

            <button type="button" class="btn btn-danger" (click)="confirmDelete()">Delete</button>

            <button type="submit" class="btn btn-info ms-2">Update</button>
        </form>
    </div>

    <div *ngSwitchCase="'table'">
        <div>
          <div *ngIf="!(loader.isLoading$ | async)">
            <app-search-bar label="Search Brands" (onChange)="filterList($event)"></app-search-bar>
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Brand Name</th>
                </tr>
              </thead>
              <tbody class="table-group-divider table-divider-color divider-color">
                <tr *ngFor="let brand of filteredBrands" (click)="onBrandClick(brand, brand.id)">
                  <td>
                    <img [src]="brand.brandImage">
                    {{ brand.brandName }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="mx-auto w-25 mt-5" *ngIf="loader.isLoading$ | async">
            <div class="spinner-border text-warning" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
    </div>

    <div *ngSwitchCase="'empty'" class="p-4">
      <app-empty></app-empty>
    </div>

    <div class="floating-button-container">
        <button class="float" (click)="switchView(page == 'form' ? 'table' : 'form')">
          <span class="add-icon">+</span>
        </button>
    </div>

    <div *ngSwitchCase="'form'" class="form-container">
      <div class="text-center" *ngIf="loader.isLoading$ | async">
        <div class="spinner-border text-warning" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

        <form (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label class="d-block mb-2">Brand Information</label>
                <app-image-upload [src]="brandImage" (refEvent)="getURL($event)"></app-image-upload>
                <input type="text" placeholder="Brand Name" [(ngModel)]="selectedBrand.brandName" name="brandName"
                    class="form-input w-50">
            </div>

            <button type="submit" class="btn btn-info">Submit</button>
        </form>
    </div>
</div>