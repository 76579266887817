import { Component, OnInit } from '@angular/core';
import { Game } from '../shared/game/game';
import { Team } from '../shared/team/team';
import { ToastrService } from 'ngx-toastr';
import { CrudTeamService } from '../shared/team/crud-team.service';
import { CrudGameService } from '../shared/game/crud-game.service';
import { Reference } from '@angular/fire/compat/storage/interfaces';
import { Timestamp } from 'firebase/firestore';
import { GameResult } from '../shared/game/result';
import { LoadingService } from '../shared/loading/loading.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-games-table',
  templateUrl: './games-table.component.html',
  styleUrls: ['./games-table.component.css']
})
export class GamesTableComponent implements OnInit {
  games: Game[];
  results: GameResult[]
  teams: Team[];

  selectedGame: Game;
  startDateTimestamp: Timestamp;
  resultDateTimestamp: Timestamp;
  newGame: Game;
  selectedresult: GameResult;

  page: string = 'table';

  constructor(
    private crudService: CrudGameService,
    private crudTeamService: CrudTeamService,
    private toastr: ToastrService,
    public loader: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
      activatedRoute.queryParams.subscribe(params => {
        this.page = params['page'];
        console.log(params);
        if (!this.page) {
          this.page = 'table';
        }

        if (this.page == 'detail' && !this.selectedGame) {
          this.switchView('table');
        }
      })

      this.selectedGame = {
        date: Timestamp.now(),
        image: '',
        resultId: '',
        startTime: Timestamp.now(),
        team1Id: '',
        team2Id: ''
      }

      this.newGame = {
        date: Timestamp.now(),
        image: '',
        resultId: '',
        startTime: Timestamp.now(),
        team1Id: '',
        team2Id: ''
      }
    }

  ngOnInit() {
    this.loader.showLoading();
    this.crudService.getGamesList().subscribe((data: Game[]) => {
      this.games = data;
      if (this.games.length == 0) {
        this.page = 'empty';
      }
      console.log(this.games);
    })

    this.loader.showLoading();
    this.crudService.getResultsList().subscribe((data: GameResult[]) => {
      this.results = data;
      if (this.results.length == 0) {
        this.page = 'empty';
      }
      console.log(this.results);
    })

    this.loader.showLoading();
    this.crudTeamService.getTeamsList().subscribe((data: Team[]) => {
      this.teams = data;
      if (this.teams.length == 0) {
        this.page = 'empty';
      }
      this.loader.hideLoading();
      console.log(this.teams);
    });
  }

  onGameClick(game: Game, id: string) {
    console.log('Clicked game: ' + game.id);
    this.selectedGame = game;
    this.switchView('detail');
    this.selectedresult = this.results.find((result) => result.id == game.resultId);
  }

  switchView(view: string) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: view
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });
  }

  getTeam(id: string) {
    return this.teams.find((team) => team.id == id);
  }

  onSubmit() {
    const newResult: GameResult = {
      resultDate: null,
      scoreTeam1: '',
      scoreTeam2: '',
      winnerTeam: ''
    }

    this.loader.showLoading();
    this.crudService.addResult(newResult).then((ref) => {
      this.newGame.resultId = ref.id;
      this.crudService.addGame(this.newGame, this.getTeam(this.newGame.team1Id).teamName, this.getTeam(this.newGame.team2Id).teamName).then((ref) => {
        alert("Game added");
        this.loader.hideLoading();

        this.switchView('table');
      });
    });
  }

  upcoming(game: Game): boolean {
    return game.date.toDate() > (new Date());
  }

  updateGame(game: Game) {
    this.crudService.updateGame(game.id, game);
    alert("Game updated");
  }

  updateResult(result: GameResult) {
    this.crudService.updateResult(result.id, result).then(() => alert("Result updated"));
  }

  getURL(ref: Reference, parameter: string) {
    ref.getDownloadURL().then((url) => {
      console.log(url);
      switch (parameter) {
        case 'selected':
          this.selectedGame.image = url;
          break;

        case 'new':
          this.newGame.image = url;
          break;

        default:
          break;
      }
    })
  }

  toTimeStamp(event: any, parameter: string) {
    switch (parameter) {
      case 'startDate':
        this.startDateTimestamp = Timestamp.fromDate(event.target.value);
        break;
    
      case 'resultDate':
        this.resultDateTimestamp = Timestamp.fromDate(event.target.value);
        break;

      default:
        break;
    }
    
  }

  confirmDelete(game: Game) {
    this.crudService.deleteGame(game.id);
    alert("Game deleted");

    const confirmation = window.confirm('Are you sure you want to delete this game?');
    if (confirmation) {
      this.crudService.deleteGame(this.selectedGame.id).then(() => {
        alert("Game deleted");
        this.switchView('table');
      });
    }
  }
}
