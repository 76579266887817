<mdb-form-control class="mb-2">
    <input mdbInput class="form-control" type="search" (input)="searchTermChange($event)" name="search">
    <label mdbLabel class="form-label" for="search" style="color: #ED7433;">{{label}}</label>
</mdb-form-control>

<div *ngIf="filters" class="mb-2">
    <input
        mdbRadio
        type="radio"
        class="btn-check"
        name="options"
        id="option1"
        autocomplete="off"
        [checked]="true"
        value="d"
        (input)="filterChange('')"
    />
    <label class="btn btn-rounded me-2 chip" for="option1">All</label>

    <ng-container *ngFor="let filter of filters; index as i">
        <input
            mdbRadio
            type="radio"
            class="btn-check"
            name="options"
            [id]="'option' + (i + 2)"
            autocomplete="off"
            (input)="filterChange(filter)"
        />
    <label class="btn btn-rounded me-2 chip" [for]="'option' + (i + 2)">{{filter}}</label>
    </ng-container>
</div>
    
