<!-- SIDEBAR -->

<section id="sidebar" [ngClass]="status ? 'hide' : ''">
  <a class="brand">
    <img src="../../assets/images/brand_logo.png" alt="The Zone Logo" class="logo">
    <span class="text">The Zone Admin</span>
  </a>
  <ul class="side-menu top">
    <li (click)="changeTab('Dashboard')" [class.active]="selectedTab === 'Dashboard'">
      <a routerLink="/dashboard">
        <i class='bx bxs-dashboard'></i>
        <span class="text">Dashboard</span>
      </a>
    </li>
  

    <li [class.active]="selectedTab === 'Users'">
      <a routerLink="users">
        <i class='bx bx-run'></i>
        <span class="text">Users</span>
      </a>
    </li>

    <li [class.active]="selectedTab === 'Players'">
      <a routerLink="players">
        <i class='bx bx-run'></i>
        <span class="text">Players</span>
      </a>
    </li>

    <li [class.active]="selectedTab === 'Coaches'">
      <a routerLink="coaches">
        <i class='bx bx-football'></i>
        <span class="text">Coaches</span>
      </a>
    </li>

    <!-- <li (click)="changeTab('Fans')" [class.active]="selectedTab === 'Fans'">
      <a routerLink="#">
        <i class='bx bx-user-voice'></i>
        <span class="text">Fans</span>
      </a>
    </li> -->

    <li [class.active]="selectedTab === 'Teams'">
      <a routerLink="teams">
        <i class='bx bx-group'></i>
        <span class="text">Teams</span>
      </a>
    </li>

    <li [class.active]="selectedTab === 'Brands'">
      <a routerLink="brands">
        <i class='bx bx-store'></i>
        <span class="text">Brands</span>
      </a>
    </li>

    <li [class.active]="selectedTab === 'Games'">
      <a routerLink="games">
        <i class='bx bx-basketball'></i>
        <span class="text">Games</span>
      </a>
    </li>

    <li [class.active]="selectedTab === 'Competitions'">
      <a routerLink="competitions">
        <i class='bx bx-table'></i>
        <span class="text">Competitions</span>
      </a>
    </li>

    <li [class.active]="selectedTab === 'Bookings'">
      <a routerLink="bookings">
        <i class='bx bx-bookmark'></i>
        <span class="text">Bookings</span>
      </a>
    </li>

    <li [class.active]="selectedTab === 'Courts'">
      <a routerLink="courts">
        <i class='bx bx-location-plus'></i>
        <span class="text">Courts</span>
      </a>
    </li>

    <li [class.active]="selectedTab === 'Runs'">
      <a routerLink="runs">
        <i class='bx bx-basketball'></i>
        <span class="text">Runs</span>
      </a>
    </li>
    <!-- <li (click)="changeTab('Venues')" [class.active]="selectedTab === 'Venues'">
      <a routerLink="#">
        <i class='bx bx-store'></i>
        <span class="text">Venues</span>
      </a>
    </li> -->
  </ul>
  <ul class="side-menu">
    <li>
      <a>
        <i class='bx bxs-cog'></i>
        <span class="text">Settings</span>
      </a>
    </li>
    <li>
      <a href="/sign-in" class="logout">
        <i class='bx bxs-log-out-circle'></i>
        <span class="text" (click)="logout()">Logout</span>
      </a>
    </li>
  </ul>
</section>
<!-- SIDEBAR -->

<!-- CONTENT -->
<section id="content">
  <!-- NAVBAR -->
  <nav>
    <i (click)="addToggle()" class='bx bx-menu'></i>
    <a class="nav-link">Categories</a>
    <form action="#">
      <div class="form-input">
        <input type="search" placeholder="Search...">
        <button type="button" class="search-btn"><i class='bx bx-search'></i></button>
      </div>
    </form>
    <!-- <a class="notification">
      <i class='bx bxs-bell'></i>
      <span class="num">8</span>
    </a> -->
    <div mdbDropdown class="dropdown">
      <a
        class="notification"
        role="button"
        id="dropdownMenuLink"
        mdbDropdownToggle
        aria-expanded="false"
      >
        <i class='bx bxs-bell'></i>
        <span class="num">{{notifications?.length || 0}}</span>
      </a>

      
      <div mdbDropdownMenu class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink">
        <mdb-tabs class="tabs">
          <mdb-tab title="All">
            <ul class="ps-0 scrollable">
              <li *ngFor="let notification of notifications" class="dropdown-item">
                <div class="notification-item border-bottom border-warning">
                  <h6>{{notification.title}}</h6>
                  {{notification.description}}
                </div>               
              </li>
            </ul>
          </mdb-tab>
          <mdb-tab title="OE">
            <ul class="ps-0 scrollable">
              <li *ngFor="let notification of engineNotifications" class="dropdown-item">
                <div class="notification-item border-bottom border-warning">
                  <h6>{{notification.title}}</h6>
                  {{notification.description}}
                </div>
              </li>
            </ul>
          </mdb-tab>
          <mdb-tab title="App">
            <ul class="ps-0 scrollable">
              <li *ngFor="let notification of appNotifications" class="dropdown-item">
                <div class="notification-item border-bottom border-warning">
                  <h6>{{notification.title}}</h6>
                  {{notification.description}}
                </div>
              </li>
            </ul>
          </mdb-tab>
        </mdb-tabs>
      </div>
    </div>

    <a href="#" class="profile">
      <img src="https://secure.gravatar.com/avatar/d09eaad01aea86c51b4f892b4f8abf6f?s=100&d=wavatar&r=g">
    </a>
  </nav>

  <main>
    <div class="head-title">
      <div class="left">
        <h1>{{ selectedTab }}</h1>
        <ul class="breadcrumb">
          <li>
            <a>{{ selectedTab }}</a>
          </li>
          <li><i class='bx bx-chevron-right'></i></li>
          <li>
            <a class="active">Home</a>
          </li>
        </ul>
      </div>
      <a class="btn-download">
        <i class='bx bxs-cloud-download'></i>
        <span class="text">Download PDF</span>
      </a>
    </div>


      <!-- ... other content ... -->
    
      <ul class="box-info" *ngIf="selectedTab === 'Dashboard'">
        <li>
          <i class='bx bx-run'></i>
          <span class="text">
            <p>Players</p>
            <h3>{{ playerCount }}</h3>
           
          </span>
        </li>
        <li>
          <i class='bx bx-football'></i>
          <span class="text">
            <p>Coaches</p>
            <h3>{{coachCount}}</h3>
         
          </span>
        </li>
        <li>
          <i class='bx bx-group'></i>
          <span class="text">
            <p>Teams</p>
            <h3>{{teamCount}}</h3>
          </span>
        </li>

        <li>
          <i class='bx bx-location-plus'></i>
          <span class="text">
            <p>Courts</p>
            <h3>{{courtCount}}</h3>
          </span>
        </li>

        <li>
          <i class='bx bx-basketball'></i>
          <span class="text">
            <p>Games</p>
            <h3>{{gameCount}}</h3>
          </span>
        </li>

        <li>
          <i class='bx bx-table'></i>
          <span class="text">
            <p>Competitions</p>
            <h3>{{competitionCount}}</h3>
          </span>
        </li>

        <li>
          <i class='bx bx-store'></i>
          <span class="text">
            <p>Brands</p>
            <h3>{{brandCount}}</h3>
          </span>
        </li>

        <li>
          <i class='bx bx-bookmark'></i>
          <span class="text">
            <p>Bookings</p>
            <h3>{{bookingCount}}</h3>
          </span>
        </li>
      </ul>
    
      <router-outlet></router-outlet>
 <!--  <div *ngIf="selectedTab === 'Users'">
     <app-users-table></app-users-table>
  </div>

  <div *ngIf="selectedTab === 'Players'">
    <app-players-table></app-players-table>
  </div>

  <div *ngIf="selectedTab === 'Coaches'">
    <app-coach-table></app-coach-table>
  </div>

  <div *ngIf="selectedTab === 'Teams'">
    <app-teams-table></app-teams-table>
  </div>

  <div *ngIf="selectedTab === 'Brands'">
    <app-brands-table></app-brands-table>
  </div>

  <div *ngIf="selectedTab === 'Games'">
    <app-games-table></app-games-table>
  </div>

  <div *ngIf="selectedTab === 'Competitions'">
    <app-competitions-table></app-competitions-table>
  </div>

  <div *ngIf="selectedTab === 'Bookings'">
    <app-bookings-table></app-bookings-table>
  </div>

  <div *ngIf="selectedTab === 'Courts'">
    <app-courts-table></app-courts-table>
  </div>

  <div *ngIf="selectedTab === 'Runs'">
    <app-runs-table></app-runs-table>
  </div>

  <div *ngIf="selectedTab === 'Venues'">
    <p>{{ venuesData }}</p>
  </div> -->
    
      <!-- ... other content ... -->
    </main>
    