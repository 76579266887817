import { Injectable } from '@angular/core';
import { Game } from './game';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { GameResult } from './result';
import { PushNotificationService } from '../services/push-notification.service';

@Injectable({
  providedIn: 'root'
})
export class CrudGameService {
  private gamesCollection: AngularFirestoreCollection<Game>;
  private resultsCollection: AngularFirestoreCollection<GameResult>;
  games: Observable<Game[]>;
  results: Observable<GameResult[]>;

  constructor(private afs: AngularFirestore, private push: PushNotificationService) {
    this.gamesCollection = afs.collection('Games');
    this.resultsCollection = afs.collection('GameResults');
    this.games = this.gamesCollection.valueChanges({idField: 'id'});
    this.results = this.resultsCollection.valueChanges({idField: 'id'});
  }

  addGame(game: Game, team1: string, team2: string): Promise<DocumentReference> {
    this.push.sendNotification('New game', `New game is up between ${team1} vs ${team2}, check it out!`, 'Games')
      .then((ref) => console.log("Notification sent"));
    return this.gamesCollection.add(game);
  }

  addResult(result: GameResult): Promise<DocumentReference> {
    return this.resultsCollection.add(result);
  }
  getGamesList(): Observable<Game[]> {
    return this.games;
  }

  getResultsList(): Observable<GameResult[]> {
    return this.results;
  }

  updateGame(id: string, game: Game) {
    this.gamesCollection.doc(id).update(game);
  }

  updateResult(id: string, result: GameResult) {
    this.push.sendNotification('New game result', '', 'Games')
      .then((ref) => console.log("Notification sent"));
    return this.resultsCollection.doc(id).update(result);
  }

  deleteGame(id: string) {
    return this.gamesCollection.doc(id).delete();
  }
}
