import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CrudTeamService } from '../shared/team/crud-team.service';
import { Team } from '../shared/team/team';
import { Player } from '../shared/player/player';
import { Coach } from '../shared/coach/coach';
import { CrudCoachService } from '../shared/coach/crud-coaches.service';
import { CrudPlayerService } from '../shared/player/crud-player.service';
import { teamStatistics } from '../shared/team/team-statistics';
import { Reference } from '@angular/fire/compat/storage/interfaces';
import { LoadingService } from '../shared/loading/loading.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-teams-table',
  templateUrl: './teams-table.component.html',
  styleUrls: ['./teams-table.component.css']
})
export class TeamsTableComponent implements OnInit {
  teams: Team[];
  filteredTeams: Team[];
  players: Player[];
  coaches: Coach[];
  teamStatistics: teamStatistics[];
  
  isFormVisible: boolean;
  newTeam: Team;
  selectedTeam: Team; // Store the selected team
  selectedTeamStatistics: teamStatistics;
  isDetailVisible = false;
  selectedTeamId: string | null = null;
  isModalVisible: boolean = false;

  //showForm: boolean = false;
  teamImage: string = '';
  teamName: string = '';
  teamDescription: string = '';
  teamPlayers: string[] = [];
  teamCoachId: string = '';
  teamCaptainId: string = '';
  teamStatisticsId: string = '';

  page: string = 'table';

  constructor(
    private crudService: CrudTeamService,
    public coachCrud: CrudCoachService,
    public playerCrud: CrudPlayerService,
    private toastr: ToastrService,
    public loader: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
      activatedRoute.queryParams.subscribe(params => {
        this.page = params['page'];
        console.log(params);
        if (!this.page) {
          this.page = 'table';
        }

        if (this.page == 'detail' && !this.selectedTeam) {
          this.switchView('table');
        }
      })
      
      this.selectedTeam = {
        teamName: '',
        teamCaptainId: '',
        teamCoachId: '',
        teamDescription: '',
        teamImage: '',
        teamPlayers: [],
        teamStatisticsId: ''
      };

      this.newTeam = {
        teamName: '',
        teamCaptainId: '',
        teamCoachId: '',
        teamDescription: '',
        teamImage: '',
        teamPlayers: [],
        teamStatisticsId: ''
      }
    }

  ngOnInit() {
    this.loader.showLoading();
    this.crudService.getTeamsList().subscribe((data: Team[]) => {
      this.teams = data;
      this.filteredTeams = this.teams;
      if (this.teams.length == 0) {
        this.page = 'empty';
      }
      this.loader.hideLoading();
      console.log(this.teams);
    });

    this.loader.showLoading();
    this.crudService.getTeamStatisticsList().subscribe((data: teamStatistics[]) => {
      this.teamStatistics = data;
      if (this.teamStatistics.length == 0) {
        this.page = 'empty';
      }
      this.loader.hideLoading();
      console.log(this.teamStatistics);
    });

    this.loader.showLoading();
    this.coachCrud.getCoachsList().subscribe((data: Coach[]) => {
      this.coaches = data;
      if (this.coaches.length == 0) {
        this.page = 'empty';
      }
      this.loader.hideLoading();
    });

    this.loader.showLoading();
    this.playerCrud.getPlayersList().subscribe((data: Player[]) => {
      this.players = data;
      if (this.players.length == 0) {
        this.page = 'empty';
      }
      this.loader.hideLoading();
    });
  }

  onteamClick(team: Team, teamId: string ) {
    console.log('Clicked team '+ team.teamName);
    this.selectedTeam = team
    this.selectedTeamId = teamId;
    this.switchView('detail');
    this.selectedTeamStatistics = this.teamStatistics.find(({id}) => id === team.teamStatisticsId);
    console.log(this.selectedTeamStatistics);
    console.log('statistics id: ' + team.teamStatisticsId);
  }

  switchView(view: string) {
    this.filteredTeams = this.teams;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: view
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });
  }

  updateTeam(team: Team) {
    this.crudService.updateTeam(this.selectedTeamId, this.selectedTeam).then(() => {
      alert('Team updated successfully')
      this.isDetailVisible=false;
    }, (error) => {
      // Handle the error scenario here
      console.log(error);
    });
  }

  updateTeamStatistics() {
    this.crudService.updateTeamStatistics(this.selectedTeamStatistics.id, this.selectedTeamStatistics).then(() => {
      alert('Statistics update succesfully');
    })
  }

  onSubmit() {
    const team: Team = {
      teamImage: this.teamImage,
      teamName: this.teamName,
      teamDescription: this.teamDescription,
      teamPlayers: this.teamPlayers,
      teamCoachId: this.teamCoachId,
      teamCaptainId: this.teamCaptainId,
      teamStatisticsId: this.teamStatisticsId
    };

    this.loader.showLoading();
    this.crudService.addTeam(team).then(() => {
      alert('Team added successfully');
      this.isFormVisible = false;
      this.loader.hideLoading();
      this.switchView('table');
    });
  }

  showForm(): void {
    this.isFormVisible = !this.isFormVisible; // Toggle the form visibility
  }
  
  hideForm() {
    this.isFormVisible = false;
  }

  getCoach(id: string): Coach {
    return this.coaches.find((coach) => coach.id == id);
  }

  getPlayer(id: string): Player {
    return this.players.find((player) => player.id == id);
  }

  confirmDelete(selectedTeam: any) {
    const confirmation = window.confirm('Are you sure you want to delete this team?');
    if (confirmation) {
      // Delete the selected player
      this.crudService.deleteTeam(selectedTeam.id).then(()=>{
        alert("Team deleted successfully");
        this.switchView('table');
      }).catch((error)=>{
        alert("Error deleting team")
      });
    }
  }

  getURL(ref: Reference, parameter: string) {
    ref.getDownloadURL().then((url) => {
      console.log(url);
      switch (parameter) {
        case 'selected':
          this.selectedTeam.teamImage = url;
          break;
      
        case 'new':
          this.newTeam.teamImage = url;
          break;
      }
      
    })
  }

  filterList(searchTerm: string) {
    this.filteredTeams = this.teams.filter((team) => {
      return team.teamName.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }
}
