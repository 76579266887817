import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent {

  @Input() label: string;
  @Input() filters: string[];

  @Output() onChange = new EventEmitter<string>();
  @Output() onFilter = new EventEmitter<string>();

  searchTermChange($event: any) {
    this.onChange.emit($event.target.value);
    //console.log($event.target.value);
  }

  filterChange($event: string) {
    this.onFilter.emit($event);
    console.log($event);
  }
}
