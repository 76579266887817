import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

export interface Notification {
  title: string;
  description: string;
  triggeredFrom: string;
  time: string;
  date: string;
  isRead: boolean;
  isPublic: boolean;
  category: string;
}

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  private notificationsCollection: AngularFirestoreCollection<Notification>;
  private notifications: Observable<Notification[]>;

  constructor(private afs: AngularFirestore) {
    this.notificationsCollection = afs.collection('Notifications');
    this.notifications = this.notificationsCollection.valueChanges();
  }

  sendNotification(title: string, description: string, category: string) {
    return this.notificationsCollection.add({
      title: title,
      description: description,
      triggeredFrom: "OE",
      time: new Date().toLocaleTimeString(),
      date: new Date().toLocaleDateString(),
      isRead: false,
      isPublic: true,
      category: category
    });
  }

  getNotificationsList() {
    return this.notifications;
  }
}
