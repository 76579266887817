<div [ngSwitch]="page">
    <div *ngSwitchCase="'detail'" class="p-4">
        <button type="button" class="btn btn-tertiary" mdbRipple (click)="switchView('table')">
            <i class='bx bx-arrow-back'></i>
            <span>Back to runs</span>
        </button>

        <h3>Update Run</h3>

        <form (ngSubmit)="updateRun()">
            <div class="form-group">
                <label class="d-block mb-2">Run Information</label>

                <app-image-upload [src]="selectedRun.imageUrl" (refEvent)="getURL($event, 'selected')"></app-image-upload>
                
                <label class="d-block mt-3">Run Title</label>
                <input type="text" [(ngModel)]="selectedRun.title" name="title"
                    class="form-input w-50 mt-2">

                <label class="d-block">Description</label>
                <input type="text" [(ngModel)]="selectedRun.description" name="description"
                    class="form-input w-50 mt-2">

                <label class="d-block">Host Name</label>
                <input type="text" [(ngModel)]="selectedRun.hostFullName" name="hostFullName"
                    class="form-input w-50 mt-2">

                <label class="d-block">Email</label>
                <input type="email" [(ngModel)]="selectedRun.email" name="email"
                    class="form-input w-50 mt-2">

                <label class="d-block">Cell Number</label>
                <input type="text" [(ngModel)]="selectedRun.cellNumber" name="cellNumber"
                    class="form-input w-50 mt-2">


                <label class="d-block">Players</label>
                <ng-select
                    name="playersIds"
                    [multiple]="true"
                    [(ngModel)]="selectedRun.playerIds"
                    placeholder="Select Players"
                    class="w-50">
                    <ng-option *ngFor="let player of players" [value]="player.id">{{ player.firstName }} {{ player.lastName }}</ng-option>
                </ng-select>

                <label class="d-block">Court</label>
                <ng-select
                    name="courtID"
                    placeholder="Select court"
                    [(ngModel)]="selectedRun.courtID"
                    class="w-50">
                    <ng-option *ngFor="let court of courts" [value]="court.id">{{ court.name }}</ng-option>
                </ng-select>

                <label class="d-block">Type</label>
                <ng-select
                    name="type"
                    placeholder="Select type"
                    [(ngModel)]="selectedRun.type"
                    class="w-50">
                    <ng-option *ngFor="let type of types" [value]="type">{{ type }}</ng-option>
                </ng-select>

                <label class="d-block">Time</label>
                <input type="text" [(ngModel)]="selectedRun.time" name="time"
                type="time"
                [(ngModel)]="selectedRun.time"
                    class="form-input w-50 mt-2">

                <label class="d-block">Date</label>
                <input type="date" [(ngModel)]="selectedRun.date" name="date"
                    class="form-input w-50 mt-2">
            </div>
            <button type="submit" class="btn btn-info mt-2">Update</button>
        </form>
    </div>

    <div *ngSwitchCase="'table'" class="p-4">
        <div *ngIf="!(loader.isLoading$ | async)">
            <app-search-bar label="Search Runs" (onChange)="searchTerm.next($event)" (onFilter)="filter.next($event)" [filters]="types"></app-search-bar>
            <table class="table table-striped table-hover">
                <thead>
                    <th scope="col">Title</th>
                    <th scope="col">Host</th>
                    <th scope="col">Type</th>
                    <th scope="col">Court</th>
                </thead>
    
                <tbody class="table-group-divider table-divider-color divider-color">
                    <tr *ngFor="let run of filteredRuns " (click)="onRunClick(run)" scope="row">
                        <td>
                            <img [src]="run.imageUrl">
                            {{ run.title }}
                        </td>
                        <td>{{ run.hostFullName }}</td>
                        <td>{{ run.type }}</td>
                        <td>{{ getCourt(run.courtID).name }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="mx-auto w-25 mt-5" *ngIf="loader.isLoading$ | async">
            <div class="spinner-border text-warning" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>

    <div class="floating-button-container">
        <button class="float" (click)="switchView(page == 'form' ? 'table' : 'form')">
        <span class="add-icon">+</span>
        </button>
    </div>

    <div *ngSwitchCase="'form'">
        <div class="text-center" *ngIf="loader.isLoading$ | async">
            <div class="spinner-border text-warning" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <form (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label class="d-block mb-2">Run Information</label>

                <app-image-upload [src]="newRun.imageUrl" (refEvent)="getURL($event, 'new')"></app-image-upload>
                
                <label class="d-block mt-3">Run Title</label>
                <input type="text" [(ngModel)]="newRun.title" name="title"
                    class="form-input w-50 mt-2">

                <label class="d-block">Description</label>
                <input type="text" [(ngModel)]="newRun.description" name="description"
                    class="form-input w-50 mt-2">

                <label class="d-block">Host Name</label>
                <input type="text" [(ngModel)]="newRun.hostFullName" name="hostFullName"
                    class="form-input w-50 mt-2">

                <label class="d-block">Email</label>
                <input type="email" [(ngModel)]="newRun.email" name="email"
                    class="form-input w-50 mt-2">

                <label class="d-block">Cell Number</label>
                <input type="text" [(ngModel)]="newRun.cellNumber" name="cellNumber"
                    class="form-input w-50 mt-2">


                <label class="d-block">Players</label>
                <ng-select
                    name="playersIds"
                    [multiple]="true"
                    [(ngModel)]="newRun.playerIds"
                    placeholder="Select Players"
                    class="w-50">
                    <ng-option *ngFor="let player of players" [value]="player.id">{{ player.firstName }} {{ player.lastName }}</ng-option>
                </ng-select>

                <label class="d-block">Court</label>
                <ng-select
                    name="courtID"
                    placeholder="Select court"
                    [(ngModel)]="newRun.courtID"
                    class="w-50">
                    <ng-option *ngFor="let court of courts" [value]="court.id">{{ court.name }}</ng-option>
                </ng-select>

                <label class="d-block">Type</label>
                <ng-select
                    name="type"
                    placeholder="Select type"
                    [(ngModel)]="newRun.type"
                    class="w-50">
                    <ng-option *ngFor="let type of types" [value]="type">{{ type }}</ng-option>
                </ng-select>

                <label class="d-block">Time</label>
                <input type="text" [(ngModel)]="newRun.time" name="time"
                type="time"
                [(ngModel)]="newRun.time"
                    class="form-input w-50 mt-2">

                <label class="d-block">Date</label>
                <input type="date" [(ngModel)]="newRun.date" name="date"
                    class="form-input w-50 mt-2">
            </div>

            <button type="submit" class="btn btn-info ms-2">Submit</button>
        </form>
    </div>
</div>
