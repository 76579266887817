import { Injectable } from '@angular/core';
import { Brand } from './Brand';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CrudBrandService {
  private brandsCollection: AngularFirestoreCollection<Brand>;
  brands: Observable<Brand[]>;

  constructor(private afs: AngularFirestore) {
    this.brandsCollection = afs.collection<Brand>('Brands');
    this.brands = this.brandsCollection.valueChanges({ idField: 'id'});
  }

  addBrand(brand: Brand) {
    return this.brandsCollection.add(brand);
  }

  getBrandsList(): Observable<Brand[]> {
    return this.brands;
  }

  updateBrand(id: string, brand: Brand): Promise<void> {
    return this.brandsCollection.doc<Brand>(id).update(brand);
  }

  deleteBrand(id: string) {
    return this.brandsCollection.doc(id).delete();
  }
}
