import { Injectable } from '@angular/core';
import { Team } from './team';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable, map } from 'rxjs';
import { DocumentReference } from 'firebase/firestore';
import { teamStatistics } from './team-statistics';

@Injectable({
  providedIn: 'root'
})
export class CrudTeamService {
  private teamsCollection: AngularFirestoreCollection<Team>;
  private teamStatisticsCollection: AngularFirestoreCollection<teamStatistics>;
  teams: Observable<Team[]>;
  teamStatistics: Observable<teamStatistics[]>;

  constructor(private afs: AngularFirestore) {
    this.teamsCollection = afs.collection('Teams');
    this.teams = this.teamsCollection.valueChanges({idField: 'id'});
    this.teamStatisticsCollection = afs.collection('TeamStatistics');
    this.teamStatistics = this.teamStatisticsCollection.valueChanges({idField: 'id'});
  }

  addTeam(team: Team) {
    return this.teamsCollection.add(team);
  }

  getTeamsList(): Observable<Team[]> {
    return this.teams;
  }

  getTeam(id: string): Observable<Team> {
    return this.teamsCollection.doc(id).valueChanges();
  }

  updateTeam(id: string, team: Team): Promise<void> {
    return this.teamsCollection.doc(id).update(team);
  }

  deleteTeam(id: string): Promise<void> {
    return this.teamsCollection.doc(id).delete();
  }

  getTeamStatisticsList(): Observable<teamStatistics[]> {
    return this.teamStatistics;
  }

  updateTeamStatistics(id: string, statistics: teamStatistics) {
    return this.teamStatisticsCollection.doc<teamStatistics>(id).update(statistics);
  }
}
