import { Injectable } from '@angular/core';
import { Coach } from '../coach/coach';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentReference } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class CrudCoachService {
  private coachesCollection: AngularFirestoreCollection<Coach>;
  coaches: Observable<Coach[]>;

  constructor(private afs: AngularFirestore) { 
    this.coachesCollection = this.afs.collection<Coach>('Coaches');
    this.coaches = this.coachesCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Coach;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );

  }

    // Create Coach
    addCoach(coach: Coach) {
      return this.coachesCollection.add(coach);
    }
  
    // Fetch Single Coach Object
    getCoach(id: string): Observable<Coach> {
      return this.coachesCollection.doc<Coach>(id).valueChanges();
    }
  
    // Fetch Coachs List
    getCoachsList(): Observable<Coach[]> {
      return this.coaches;
    }
  
    // Update Coach Object
    updateCoach(id: string, Coach: Coach): Promise<void> {
      return this.coachesCollection.doc<Coach>(id).update(Coach);
    }
  
    // Delete Coach Object
    deleteCoach(id: string): Promise<void> {
      return this.coachesCollection.doc<Coach>(id).delete();
    }
  
    getCoachsCount() {
      return this.coachesCollection.get().toPromise().then(snapshot => {
        return snapshot.size;
      });
  }
}
