import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignInComponent } from './sign-in/sign-in.component';

import { AuthenticationService } from './shared/services/authentication.service';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabase, AngularFireDatabaseModule } from '@angular/fire/compat/database';

import { environment as envProd } from '../environments/environment.prod';
import { environment as envDev } from '../environments/environment.dev';

import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbPopoverModule } from 'mdb-angular-ui-kit/popover';
import { MdbRadioModule } from 'mdb-angular-ui-kit/radio';
import { MdbRangeModule } from 'mdb-angular-ui-kit/range';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MdbScrollspyModule } from 'mdb-angular-ui-kit/scrollspy';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';
import { MdbValidationModule } from 'mdb-angular-ui-kit/validation';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PlayersTableComponent } from './players-table/players-table.component';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideDatabase, getDatabase } from '@angular/fire/database';
import { CoachsTableComponent } from './coach-table/coach-table.component';
import { FansTableComponent } from './fans-table/fans-table.component';
import { TeamsTableComponent } from './teams-table/teams-table.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrandsTableComponent } from './brands-table/brands-table.component';
import { ImageUploadComponent } from './shared/image-upload/image-upload.component';
import { GamesTableComponent } from './games-table/games-table.component';
import { CompetitionsTableComponent } from './competitions-table/competitions-table.component';
import { BookingsTableComponent } from './bookings-table/bookings-table.component';
import { CourtsTableComponent } from './courts-table/courts-table.component';
import { UsersTableComponent } from './users-table/users-table.component';
import { EmptyComponent } from './shared/empty/empty.component';
import { RunsTableComponent } from './runs-table/runs-table.component';
import { SearchBarComponent } from './shared/search-bar/search-bar.component';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    DashboardComponent,
    PlayersTableComponent,
    CoachsTableComponent,
    FansTableComponent,
    TeamsTableComponent,
    BrandsTableComponent,
    ImageUploadComponent,
    GamesTableComponent,
    CompetitionsTableComponent,
    BookingsTableComponent,
    CourtsTableComponent,
    UsersTableComponent,
    EmptyComponent,
    RunsTableComponent,
    SearchBarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(isDevMode() ? envDev.firebaseConfig : envProd.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,

    MdbAccordionModule,
    MdbCarouselModule,
    MdbCheckboxModule,
    MdbCollapseModule,
    MdbDropdownModule,
    MdbFormsModule,
    MdbModalModule,
    MdbPopoverModule,
    MdbRadioModule,
    MdbRangeModule,
    MdbRippleModule,
    MdbScrollspyModule,
    MdbTabsModule,
    MdbTooltipModule,
    MdbValidationModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(), // ToastrModule added
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    // Provide Firebase app and database based on the environment configuration
    provideFirebaseApp(() => initializeApp(isDevMode() ? envDev.firebaseConfig : envProd.firebaseConfig)),
    provideDatabase(() => getDatabase())
  ],
  providers: [AuthenticationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
