import { Component, OnInit } from '@angular/core';
import { Court } from '../shared/court/court';
import { CrudCourtService } from '../shared/court/crud-court.service';
import { LoadingService } from '../shared/loading/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-courts-table',
  templateUrl: './courts-table.component.html',
  styleUrls: ['./courts-table.component.css']
})
export class CourtsTableComponent implements OnInit {
  courts: Court[];
  filteredCourts: Court[];

  selectedCourt: Court;
  newCourt: Court;

  courtSizes = ['Half Court', 'Full Court', '1 on 1'];
  courtTypes = ['Outdoor', 'Indoor'];

  page: string = 'table';

  searchTerm: BehaviorSubject<string>;
  filter: BehaviorSubject<string>;

  constructor(
    private crudService: CrudCourtService,
    public loader: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    activatedRoute.queryParams.subscribe(params => {
      this.page = params['page'];
      console.log(params);
      if (!this.page) {
        this.page = 'table';
      }

      if (this.page == 'detail' && !this.selectedCourt) {
        this.switchView('table');
      }
    })

    this.selectedCourt = {
      address: '',
      availability: false,
      features: [],
      name: '',
      pictureUrl: '',
      size: '',
      type: ''
    }

    this.newCourt = {
      address: '',
      availability: false,
      features: [],
      name: '',
      pictureUrl: '',
      size: '',
      type: ''
    }

    this.searchTerm = new BehaviorSubject('');
    this.filter = new BehaviorSubject('');
  }

  ngOnInit(): void {
    this.loader.showLoading();
    this.crudService.getCourtsList().subscribe((data: Court[]) => {
      this.courts = data;
      this.filteredCourts = data;
      if (this.courts.length == 0) {
        this.page = 'empty';
      }
      this.loader.hideLoading();
      console.log(this.courts);
    });

    this.searchTerm.subscribe((searchTerm) => {
      this.filterList(searchTerm, this.filter.getValue());
    })

    this.filter.subscribe((filter) => {
      this.filterList(this.searchTerm.getValue(), filter);
    })
  }

  onCourtClick(court: Court) {
    console.log('Clicked ' + court.name);
    this.selectedCourt = court
    this.switchView('detail');
  }

  switchView(view: string) {
    this.filteredCourts = this.courts;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: view
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });
  }

  updateCourt() {
    this.crudService.updateCourt(this.selectedCourt.id, this.selectedCourt);
    alert("Court updated");
  }

  confirmDelete() {
    const confirmation = window.confirm('Are you sure you want to delete this court?');
    if (confirmation) {
      this.crudService.deleteCourt(this.selectedCourt.id).then(() => {
        alert("Court deleted");
        this.switchView('table');
      });
    }
  }

  onSubmit() {
    this.loader.showLoading();
    this.crudService.addCourt(this.newCourt).then(() => {
      alert("Court added");
      this.loader.hideLoading();
      this.switchView('table');
    });
  }

  filterList(_searchTerm: string, _filter: string) {
    this.filteredCourts = this.courts.filter((court) => {
      return court.name.toLowerCase().includes(_searchTerm.toLowerCase());
    }).filter((court) => {
      return court.size.toLowerCase().includes(_filter.toLowerCase());
    });
  }
}
