import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './sign-in/sign-in.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PlayersTableComponent } from './players-table/players-table.component';
import { CoachsTableComponent } from './coach-table/coach-table.component';
import { TeamsTableComponent } from './teams-table/teams-table.component';
import { BrandsTableComponent } from './brands-table/brands-table.component';
import { GamesTableComponent } from './games-table/games-table.component';
import { CompetitionsTableComponent } from './competitions-table/competitions-table.component';
import { BookingsTableComponent } from './bookings-table/bookings-table.component';
import { CourtsTableComponent } from './courts-table/courts-table.component';
import { UsersTableComponent } from './users-table/users-table.component';
import { RunsTableComponent } from './runs-table/runs-table.component';


const routes: Routes = [
  { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
  { path: 'sign-in', component: SignInComponent },
  { path: 'dashboard',
    component: DashboardComponent,
    children: [
      { path: '', pathMatch: 'full', children: [] },
      { path: 'users', component: UsersTableComponent },
      { path: 'players', component: PlayersTableComponent },
      { path: 'coaches', component: CoachsTableComponent },
      { path: 'teams', component: TeamsTableComponent },
      { path: 'brands', component: BrandsTableComponent },
      { path: 'games', component: GamesTableComponent},
      { path: 'competitions', component: CompetitionsTableComponent},
      { path: 'bookings', component: BookingsTableComponent},
      { path: 'courts', component: CourtsTableComponent},
      { path: 'runs', component: RunsTableComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
