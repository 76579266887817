import { Component } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { LoadingService } from '../shared/loading/loading.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent {
  error: boolean
  constructor(public authenticationService: AuthenticationService, public loader: LoadingService) {
    this.error = false;
  }

  signIn(email: string, password: string) {
    this.loader.showLoading();
    this.authenticationService.SignIn(email, password)
      .then(() => this.loader.hideLoading())
      .catch(() => {
        this.error = true;
        this.loader.hideLoading();
      });
  }
}
