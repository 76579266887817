import { Injectable } from '@angular/core';
import { Fan } from '../fans/fan'
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentReference } from '@angular/fire/firestore';



@Injectable({
  providedIn: 'root',
})
export class CrudFansService {
  private fansCollection: AngularFirestoreCollection<Fan>;
  fan: Observable<Fan[]>;

  constructor(private afs: AngularFirestore) {
    this.fansCollection = this.afs.collection<Fan>('Fans');
    this.fan = this.fansCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Fan;
        const id = a.payload.doc.id;
        return { id, ...data };
      }))
    );
  }

  // Create Fan
  addFan(fan: Fan) {
    return this.fansCollection.add(fan);
  }

  // Fetch Single Player Object
  getFan(id: string): Observable<Fan> {
    return this.fansCollection.doc<Fan>(id).valueChanges();
  }

  // Fetch Fans List
  getFansList(): Observable<Fan[]> {
    return this.fan;
  }

  // Update Player Object
  updateFan(id: string, fan: Fan): Promise<void> {
    return this.fansCollection.doc<Fan>(id).update(fan);
  }

  // Delete Fan Object
  deleteFan(id: string): Promise<void> {
    return this.fansCollection.doc<Fan>(id).delete();
  }

  getFansFsCount() {
    return this.fansCollection.get().toPromise().then(snapshot => {
      return snapshot.size;
    });
}

}