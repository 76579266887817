<div [ngSwitch]="page">
  <div *ngSwitchCase="'detail'" class="form-container">
    <button type="button" class="btn btn-tertiary" mdbRipple (click)="switchView('table')">
      <i class='bx bx-arrow-back'></i>
      <span>Back to players</span>
    </button>
    <h3>Update Player</h3>
    <form (ngSubmit)="updatePlayer(selectedPlayer)">
      <div class="form-group">
        <label>Avatar</label>
        <app-image-upload [src]="selectedPlayer.avatar" (refEvent)="getURL($event, 'selected')"></app-image-upload>
        <div class="form-inline mt-3">
          <div>
            <label>First Name</label>
            <input type="text" placeholder="First Name" [(ngModel)]="selectedPlayer.firstName" name="firstName"
            class="form-input">
          </div>
          
          <div>
            <label>Last Name</label>
            <input type="text" placeholder="Last Name" [(ngModel)]="selectedPlayer.lastName" name="lastName"
            class="form-input">
          </div>
        </div>

        <div class="form-inline mt-2">
          <div>
            <label>Height (cm)</label>
            <input type="number" placeholder="Height" [(ngModel)]="selectedPlayer.height" name="height" class="form-input">
          </div>
          
          <div>
            <label>Weight (kg)</label>
            <input type="number" placeholder="Weight" [(ngModel)]="selectedPlayer.weight" name="weight" class="form-input">
          </div>
        </div>
      </div>

      <div class="form-group">
        
        <div class="form-inline">
          <div>
            <label>Experience (Years)</label>
            <input type="number" placeholder="Experience" [(ngModel)]="selectedPlayer.experience" name="experience"
              class="form-input">
          </div>
          
          <div>
            <label>Jersey Number</label>
            <input type="number" placeholder="Jersey Number" [(ngModel)]="selectedPlayer.jerseyNumber" name="jerseyNumber"
            class="form-input">
          </div>
          
        </div>

        <label class="mt-2">Date of Birth</label>
        <input type="date" placeholder="Date of Birth" [(ngModel)]="selectedPlayer.dateOfBirth" name="dateOfBirth" class="form-input">

        <div class="form-inline mt-2">  
          <div>
            <label>Active on app</label>
            <input type="checkbox" placeholder="Status" [(ngModel)]="selectedPlayer.status" name="status" class="form-input">
          </div>       
        </div>

        <div class="mt-2">
          <div>
            <label>Position</label>
            <ng-select
              [(ngModel)]="selectedPlayer.position"
              name="position"
              class="w-25">
              <ng-option *ngFor="let position of positions" [value]="position">{{ position }}</ng-option>
            </ng-select>
          </div>
        </div>
      </div>

      <button type="button" class="btn btn-danger" (click)="confirmDelete(selectedPlayer)">Delete</button>

      <button type="submit" class="btn btn-info ms-2">Update</button>
    </form>

    <h3 class="mt-4">Player Statistics</h3>
    <form (ngSubmit)="updatePlayerStatistics()">
      <div class="form-group container-fluid">
        <div class="row">
          <div class="col-xxl-3 col-6">
            <label>Total points</label>
            <input type="number" [(ngModel)]="selectedPlayerStatistics.totalPoints" name="totalPoints"
              class="number-input" min="0">
          </div>
        
          <div class="col-xxl-3 col-6">
            <label>Assist</label>
            <input type="number" [(ngModel)]="selectedPlayerStatistics.assists" name="assists"
              class="number-input" min="0">
          </div>
        
          <div class="col-xxl-3 col-6">
            <label>Rebounds</label>
            <input type="number" [(ngModel)]="selectedPlayerStatistics.rebounds" name="rebounds"
              class="number-input" min="0">
          </div>

          <div class="col-xxl-3 col-6">
            <label>Steals</label>
            <input type="number" [(ngModel)]="selectedPlayerStatistics.steals" name="steals"
              class="number-input" min="0">
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn-info">Update</button>
    </form>
  </div>

  <div *ngSwitchCase="'table'">
    <div>
      <div *ngIf="!(loader.isLoading$ | async)">
        <app-search-bar label="Search Players" (onChange)="filterList($event)"></app-search-bar>  
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Name & Surname</th>
              <th>Experience</th>
              <th>Status</th>
              <th>Jersey Number</th>
            </tr>
          </thead>
          <tbody class="table-group-divider table-divider-color divider-color">
            <tr *ngFor="let player of filteredPlayers " (click)="onPlayerClick(player,player.id)">
              <td>
                <img src="https://secure.gravatar.com/avatar/d09eaad01aea86c51b4f892b4f8abf6f?s=100&d=wavatar&r=g">
                {{ player.firstName }} {{ player.lastName }}
              </td>
              <td>{{ player.experience }}</td>
              <td>{{ player.status }}</td>
              <td>{{ player.jerseyNumber }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mx-auto w-25 mt-5" *ngIf="loader.isLoading$ | async">
        <div class="spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="'empty'" class="p-4">
    <app-empty></app-empty>
  </div>

  <div class="floating-button-container">
    <button class="float" (click)="switchView(page == 'form' ? 'table' : 'form')">
      <span class="add-icon">+</span>
    </button>
    <span class="add-player-text">Add Player</span>
  </div>


  <div *ngSwitchCase="'form'" class="p-4">
    <div class="text-center" *ngIf="loader.isLoading$ | async">
      <div class="spinner-border text-warning" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <form (ngSubmit)="onSubmit()" class="player-form">
      <div class="form-group">
        <label>Avatar</label>
        <app-image-upload [src]="avatar" (refEvent)="getURL($event, 'new')"></app-image-upload>

        <div class="form-inline mt-3">
          <div>
            <label>First Name</label>
            <input type="text" placeholder="First Name" [(ngModel)]="firstName" name="firstName"
            class="form-input">
          </div>
          
          <div>
            <label>Last Name</label>
            <input type="text" placeholder="Last Name" [(ngModel)]="lastName" name="lastName"
            class="form-input">
          </div>
        </div>

        <div class="form-inline">
          <div>
            <label>Height (cm)</label>
            <input type="number" placeholder="Height" [(ngModel)]="height" name="height" class="form-input">
          </div>
          
          <div>
            <label>Weight (kg)</label>
            <input type="number" placeholder="Weight" [(ngModel)]="weight" name="weight" class="form-input">
          </div>
        </div>
      </div>

      <div class="form-group">
        
        <div class="form-inline">
          <div>
            <label>Experience (Years)</label>
            <input type="number" placeholder="Experience" [(ngModel)]="experience" name="dateOfBirth"
              class="form-input">
          </div>
          
          <div>
            <label>Jersey Number</label>
            <input type="number" placeholder="Jersey Number" [(ngModel)]="jerseyNumber" name="weight"
            class="form-input">
          </div>
          
        </div>

        <label>Date of Birth</label>
        <input type="date" placeholder="Date of Birth" [(ngModel)]="dateOfBirth" name="dateOfBirth" class="form-input">

        <div class="form-inline mt-2">
          <div>
            <label>Active on app</label>
            <input type="checkbox" placeholder="Status" [(ngModel)]="status" name="status" class="form-input">      
          </div>
          
        </div>

        <div class="mt-2">
          <label>Position</label>
          <ng-select
            [(ngModel)]="position"
            name="position"
            class="w-25">
            <ng-option *ngFor="let position of positions" [value]="position">{{ position }}</ng-option>
          </ng-select>
        </div>
      </div>

      <button type="submit" class="btn btn-info mt-2 w-25">Submit</button>
    </form>
  </div>
</div>