import { Component, OnInit } from '@angular/core';
import { Competition } from '../shared/competition/competition';
import { CrudCompetitionService } from '../shared/competition/crud-competition.service';
import { CrudTeamService } from '../shared/team/crud-team.service';
import { CrudGameService } from '../shared/game/crud-game.service';
import { Team } from '../shared/team/team';
import { Game } from '../shared/game/game';
import { Reference } from '@angular/fire/compat/storage/interfaces';
import { LoadingService } from '../shared/loading/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-competitions-table',
  templateUrl: './competitions-table.component.html',
  styleUrls: ['./competitions-table.component.css']
})
export class CompetitionsTableComponent implements OnInit {
  competitions: Competition[];
  filteredCompetitions: Competition[];
  games: Game[];
  teams: Team[];

  selectedCompetition: Competition;
  newCompetition: Competition;

  page: string = 'table';

  searchTerm: BehaviorSubject<string>;
  filter: BehaviorSubject<string>;

  constructor(
    private crudService: CrudCompetitionService,
    private crudTeamService: CrudTeamService,
    private crudGameService: CrudGameService,
    public loader: LoadingService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  )
  {
    activatedRoute.queryParams.subscribe(params => {
      this.page = params['page'];
      console.log(params);
      if (!this.page) {
        this.page = 'table';
      }

      if (this.page == 'detail' && !this.selectedCompetition) {
        this.switchView('table');
      }
    });

    this.selectedCompetition = {
      competitionImage: '',
      competitionName: '',
      endDate: '',
      gameIds: [],
      hostId: '',
      isApproved: false,
      runnerUpTeamId: '',
      winnerTeamId: '',
      startDate: '',
      trophyImage: '',
      trophyName: ''
    }

    this.newCompetition = {
      competitionImage: '',
      competitionName: '',
      endDate: '',
      gameIds: [],
      hostId: '',
      isApproved: false,
      runnerUpTeamId: '',
      winnerTeamId: '',
      startDate: '',
      trophyImage: '',
      trophyName: ''
    }

    this.searchTerm = new BehaviorSubject('');
    this.filter = new BehaviorSubject('');
  }

  ngOnInit() {
    this.loader.showLoading();
    this.crudService.getCompetitionsList().subscribe((data: Competition[]) => {
      this.competitions = data;
      this.filteredCompetitions = data;
      if (this.competitions.length == 0) {
        this.page = 'empty';
      }
      this.loader.hideLoading();
    });

    this.loader.showLoading();
    this.crudGameService.getGamesList().subscribe((data: Game[]) => {
      this.games = data;
      if (this.games.length == 0) {
        this.page = 'empty';
      }
      this.loader.hideLoading();
    });

    this.loader.showLoading();
    this.crudTeamService.getTeamsList().subscribe((data: Team[]) => {
      this.teams = data;
      if (this.teams.length == 0) {
        this.page = 'empty';
      }
      this.loader.hideLoading();
    })

    this.searchTerm.subscribe((searchTerm) => {
      this.filterList(searchTerm, this.filter.getValue());
    })

    this.filter.subscribe((filter) => {
      this.filterList(this.searchTerm.getValue(), filter);
    })
  }

  onCompetitonClick(competition: Competition) {
    console.log('Clicked ' + competition.id);
    this.selectedCompetition = competition;
    this.switchView('detail');
  }

  switchView(view: string) {
    this.filteredCompetitions = this.competitions;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: view
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });
  }

  getTeam(id: string) {
    return this.teams.find((team) => team.id == id);
  }

  getGame(id: string) {
    return this.games.find((game) => game.id == id);
  }

  onSubmit() {
    this.loader.showLoading();
    this.crudService.addCompetition(this.newCompetition).then(() => {
      alert("Competition added");
      this.loader.hideLoading();
      this.switchView('table');
    });
  }

  updateCompetition(competition: Competition) {
    this.crudService.updateCompetition(competition.id, competition);
    alert("Competition updated");
  }

  getURL(ref: Reference, parameter: string) {
    ref.getDownloadURL().then((url) => {
      console.log(url);
      switch (parameter) {
        case 'selectedComp':
          this.selectedCompetition.competitionImage = url;
          break;

        case 'newComp':
          this.newCompetition.competitionImage = url;
          break;

        case 'selectedTrophy':
          this.selectedCompetition.trophyImage = url;
          break;
        
        case 'newTrophy':
          this.newCompetition.trophyImage = url;
          break;

        default:
          break;
      }
    })
  }

  confirmDelete(competition: Competition) {
    const confirmation = window.confirm('Are you sure you want to delete this user?');
    if (confirmation) {
      this.crudService.deleteCompetition(competition.id).then(() => {
        alert("Competition deleted");
        this.switchView('table');
      });
    }
  }

  filterList(_searchTerm: string, _filter: string) {
    this.filteredCompetitions = this.competitions.filter((competition) => {
      return competition.competitionName.toLowerCase().includes(_searchTerm.toLowerCase());
    }).filter((competition) => {
      const curDate = new Date();
      switch (_filter) {
        case 'Current':     
          return ((new Date(competition.startDate)) <= curDate) && ((new Date(competition.endDate)) >= curDate);
        case 'Upcoming':
          return (new Date(competition.startDate)) >= curDate;
        case 'Past':
          return (new Date(competition.endDate)) <= curDate;
        default:
          return true;
      }
    })
  }
}
